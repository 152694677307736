import { createApp } from "vue";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min";
import App from "./App.vue";
import router from "./router";
import store from "./store";
// import { createI18n } from "vue-i18n";
import i18n from "./i18n";
// import VueCookies from "vue-cookies";

// 1. Assign app to a variable
const app = createApp(App);

//import Validation from "../src/components/validator/validator"

// 2. Assign the global variable before mounting

if (
  /Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  )
) {
  app.config.globalProperties.device = "mobile";
} else if (
  /(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(navigator.userAgent)
) {
  app.config.globalProperties.device = "tablet";
} else {
  app.config.globalProperties.device = "desktop";
}
app.config.globalProperties.intWrongLogin = 0;
app.config.globalProperties.$UserInfo = null;
// app.config.globalProperties.$cookies = VueCookies;

// 3. Use router and mount app
app.use(i18n).use(store).use(router);
app.mount("#app");
export { app };
