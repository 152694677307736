<template>
  <nav
    id="sidebarMenu"
    v-if="hintSidebar"
    class="d-md-block border-end sidebar bg-white px-2"
  >
    <div class="position-sticky pt-2">
      <ul class="nav flex-column" id="pills-tab" role="tablist">
        <!-- <div> -->
        <div v-for="(group, groupIndex) in listMenuGroup" :key="groupIndex">
          <li
            style="padding: 13px 16px"
            class="fnt-subtitle fnt-regular text-primary d-flex align-items-center"
          >
            <component
              :is="group.icon"
              width="24"
              height="24"
              class="fill-primary me-2"
            ></component>
            {{ $t(group.name) }}
          </li>
          <div
            v-for="(items, index) in group.listMenu"
            :key="index"
            class="nav-item d-flex justify-content-start"
          >   
            <a
              v-if="items.link == undefined"
              @click="(window.location.href = items.url), onCloseform()"
              class="nav-link text-dark d-flex w-100 pe-3 align-items-center ps-7 rounded-2 fnt-subtitle"
            >
              {{ $t(items.name) }}
            </a>
            <router-link
              v-if="items.link != undefined"
              :to="items.link"
              @click="onCloseform()"
              class="nav-link text-dark d-flex w-100 pe-3 align-items-center ps-7 rounded-2 fnt-subtitle"
              :class="$route.path.includes(items.link.path) ? 'active' : ''"
            >
            {{ $t(items.name) }}
            </router-link>
          </div>
          <hr />
        </div>
        <!-- </div> -->
      </ul>
    </div>
  </nav>
  <nav
    v-else-if="device != 'mobile' && device != 'tablet'"
    class="d-flex flex-column justify-content-start border-end sidebar bg-white m-0 p-0 overflow-visible nav-hint"
  >
    <div class="nav-overlay" v-if="data != null" @click="data = null"></div>
    <!-- <button @click="checkHint()">Click</button> -->
    <div class="position-sticky pt-2">
      <!-- <div> -->
      <div
        v-for="(group, groupIndex) in listMenuGroup"
        :key="groupIndex"
        class="me-1 position-relative"
      >
        <div
          class="state-menu-hint"
          style="padding: 13px 16px"
          @click="haddleMenu(groupIndex)"
        >
          <component
            :is="group.icon"
            width="24"
            height="24"
            class="fill-primary"
          ></component>
        </div>
        <div
          class="d-flex flex-column menu-hint bg-white shadow-sm rounded-2 overflow-hidden"
          v-if="groupIndex == data"
        >
          <div
            v-for="(items, index) in group.listMenu"
            :key="index"
            class="d-flex flex-column"
          >
            <a
              v-if="items.link == undefined"
              :href="items.url"
              class="nav-menu-link text-dark d-flex w-100 pe-3 align-items-center ps-3 rounded-2 fnt-subtitle"
            >
              {{ $t(items.name) }}
            </a>
            <router-link
              v-if="items.link != undefined"
              :to="items.link"
              class="nav-menu-link text-dark d-flex w-100 pe-3 align-items-center ps-3 rounded-2 fnt-subtitle"
              :class="$route.path.includes(items.link.path) ? 'active' : ''"
              @click="data = null"
            >
              {{ $t(items.name) }}
            </router-link>
            <!-- {{items.name}} -->
          </div>
        </div>
        <!-- Test -->
        <hr v-if="hintSidebar" />
      </div>
      <!-- </div> -->
    </div>
  </nav>
</template>

<script>
// import Icon from "../../components/icon/icon.vue";
// import IconCampus from "../../assets/icon/campus.vue";
import {
  prefixOutline,
  //   campusOutline,
  agencyOutline,
  //   userfieldOutline,
  templateoutline,
  positionOutline,
  //   educationlevelOutline,
  usertypeOutline,
  parameterOutline,
  BasicDataSetting,
  FindUserFill,
  //   BIconFilterOutline,
  //   FilterActive,
  //   AcademicsFill,
  EditOutline,
} from "@/components/icon/index";
import customfilter from "@/typescript/filter";
import AdsInventories from "@/typescript/inventory";
import VueCookies from "vue-cookies";
export default {
  watch: {
    hintSidebar: {
      deep: true,
      handler(val) {
        if (val) {
          this.data = null;
        }
      },
    },
  },
  computed: {},
  data() {
    return {
      listModuleAccess: [],
      showed: false,
      data: Number,
      value: false,
      menu: {},
      listMenuGroup: [
        {
          name: "187",
          icon: "BasicDataSetting",
          listMenu: [
            "005001",
            "005002",
            "005003",
            "005004",
            "005005",
            "005006",
            "005007",
            "005008",
          ],
        },
        {
          name: "401",
          icon: "FindUserFill",
          listMenu: ["005009", "005010", "005011", "005012","005013"],
        },
      ],
      hintTxt: null,
    };
  },
  components: {
    prefixOutline,
    BasicDataSetting,
    // campusOutline,
    agencyOutline,
    // userfieldOutline,
    templateoutline,
    positionOutline,
    // educationlevelOutline,
    usertypeOutline,
    parameterOutline,
    // BIconFilterOutline,
    // FilterActive,
    // AcademicsFill,
    EditOutline,
    FindUserFill,
  },
  props: {
    hintCnt: { type: String },
    margin: { type: String },
    hinthead: { type: String },
    hintSidebar: { type: Boolean },
  },
  beforeMount() {
    const listModuleAccess = VueCookies.get("moduleAccess")?.find((module) => {
      return module.intModuleID == "005";
    });
    this.listModuleAccess = listModuleAccess.lstMenuAccess//{
    //   intModuleID: "005",
    //   strModuleNameEN: "Admin System",
    //   strModuleNameTH: "System Admin",
    //   strUrl: "",
    //   strIconpath: "",
    //   lstMenuAccess: [
    //     {
    //       intMenuID: "005001",
    //       strMenuNameEN: "Campus",
    //       strMenuNameTH: "วิทยาเขต",
    //     },
    //     {
    //       intMenuID: "005002",
    //       strMenuNameEN: "Department",
    //       strMenuNameTH: "หน่วยงาน",
    //     },
    //     {
    //       intMenuID: "005003",
    //       strMenuNameEN: "Position",
    //       strMenuNameTH: "ตำแหน่ง",
    //     },
    //     {
    //       intMenuID: "005004",
    //       strMenuNameEN: "Academic",
    //       strMenuNameTH: "สำนักวิชาและหลักสูตร",
    //     },
    //     {
    //       intMenuID: "005005",
    //       strMenuNameEN: "Degree level",
    //       strMenuNameTH: "ระดับปริญญา",
    //     },
    //     {
    //       intMenuID: "005006",
    //       strMenuNameEN: "User type",
    //       strMenuNameTH: "ประเภทผู้ใช้",
    //     },
    //     {
    //       intMenuID: "005007",
    //       strMenuNameEN: "Prefix",
    //       strMenuNameTH: "คำนำหน้าชื่อ",
    //     },
    //     {
    //       intMenuID: "005008",
    //       strMenuNameEN: "Parameter",
    //       strMenuNameTH: "พารามิเตอร์",
    //     },
    //     {
    //       intMenuID: "005011",
    //       strMenuNameEN: "Select a user group",
    //       strMenuNameTH: "เลือกกลุ่มผู้ใช้",
    //     },
    //   ],
    //   url: "https://cessystem.wu.ac.th/admin/campus",
    // };
    // console.log(this.listMenuGroup, this.listMenuGroup.length)
    for(let i=0; i< this.listMenuGroup.length; i++) {
      this.listMenuGroup[i].listMenu = this.getMenuData(i)
    }
    // console.log(this.listMenuGroup)
    // document.addEventListener("click", () => {
    //   if (this.data != this.value) {
    //     console.log("Success")
    //     this.showed = false;
    //   }
    // });
    // this.gotoview()
  },

  emits: ["hintMenuMobile"],
  methods: {
    // loop RenderModel[groupIndex] *each group have all menu
    // check each menu is exits in role?
    // if exit add key link and push for return menuData to renderer
    getMenuData(intGroupIndex) {
      let listMenuData = [];
        // console.log(this.listMenuGroup[intGroupIndex].listMenu)
      for (const menuID of this.listMenuGroup[intGroupIndex].listMenu) {
        // console.log(menuID)
        let menuData = this.listModuleAccess?.find((ele) => {
          return ele.intMenuID == menuID;
        });
        if (menuData != undefined) {
          switch (menuID) {
            case "005001":
              menuData["link"] = { path: "campus" };
              menuData["name"] = "2";
              break;
            case "005002":
              menuData["link"] = { path: "agency" };
              menuData["name"] = "3";
              break;
            case "005003":
              menuData["link"] = { path: "position" };
              menuData["name"] = "7";
              break;
            case "005004":
              menuData["link"] = { path: "academic" };
              menuData["name"] = "12";
              break;
            case "005005":
              menuData["link"] = { path: "education" };
              menuData["name"] = "8";
              break;
            case "005006":
              menuData["link"] = { path: "usertype" };
              menuData["name"] = "9";
              break;
            case "005007":
              menuData["link"] = { path: "prefix" };
              menuData["name"] = "10";
              break;
            case "005008":
              menuData["link"] = { path: "parameter" };
              menuData["name"] = "11";
              break;
            case "005009":
              menuData["link"] = { path: "userField" };
              menuData["name"] = "4";
              break;
            case "005010":
              menuData["link"] = { path: "template" };
              menuData["name"] = "5";
              break;
            case "005011":
              menuData["link"] = { path: "userinfo" };
              menuData["name"] = "280";
              break;
              case "005012":
              menuData["link"] = { path: "userrolemanager" };
              menuData["name"] = "401";
              break;
              case "005013":
              menuData["link"] = { path: "userrolegroup" };
              menuData["name"] = "448";
              break;
          }
          listMenuData.push(menuData);
        }
      }
      // console.log(listMenuData)
      return listMenuData;
    },
    filterLang(items) {
      // console.log(this.defaultLang);
      if (items !== undefined && this.$i18n.locale == null) {
        return customfilter.filterLang(items, this.$i18n.locale ?? "en");
      } else if (items !== undefined && this.$i18n.locale != null) {
        return customfilter.filterLang(items, this.$i18n.locale ?? "en");
      }
    },
    onCloseform() {
      AdsInventories.blnCloseform = false;
      // console.log(AdsInventories.blnCloseform);
      this.$emit("hintMenuMobile", false);
    },
    onCheckLink(state) {
      console.log(state + " " + "Clicked");
    },
    hindNav(hintMenu) {
      this.hintTxt = hintMenu;
      // console.log("this hint", this.hintTxt);
    },
    detectmenu() {
      var url = this.$route.fullPath.split("/");
      // console.log(url[1]);

      return url[1];
    },
    onReload() {
      // if (this.detectmenu == "test") {
      setTimeout(() => {
        this.$router.go();
      }, 100);
      //}
    },
    showMenu() {
      this.showHint = !this.showHint;
      // console.log(this.showHint);
    },
    closedMenu() {
      // this.value = index;
      // console.log(this.value);
      this.data = [];
      this.showed = false;
    },
    // เปิดปิดเมนูด้านซ้านตอนซ้อนเมนู
    haddleMenu(e) {
      if (e != this.data) {
        this.data = e;
        this.value = true;
      } else {
        this.data = null;
      }

      // console.log(this.data);

      // const num = [1, 2, 3, 4, 5, 6, 7];
      // for (let index = 1; index <= num.length; index++) {
      //   this.value = index;
      //   // console.log(this.value);
      //   if (e == this.value) {
      //     this.data = this.value;
      //     this.showed = true;
      //     console.log(this.data, this.showed);
      //   }
      // }
    },
    // ตอนซ้อนเมนู เวลาคลิกแล้วให้ปิด เมนู
    onLink(e) {
      this.data = e;
      this.data = 0;
      this.showed = false;
    },
  },
  // unmounted() {
  //   document.removeEventListener("click", this.close);
  // },
};
</script>

<style lang="scss" scoped>
.nav-link span {
  margin-left: calc(16px / 2);
}

.sidebar {
  position: fixed;
  /* top: 0; */
  left: 0;
  // top: 75px;
  z-index: 9999;
  /* Behind the navbar */
  height: calc(100vh - 67px);
  background-color: var(--snClr);
  width: 240px;
  transition: 0.2s;
  overflow: scroll;

  // overflow-y: scroll;
  // width: 66px;
  &::-webkit-scrollbar {
    width: 4px;
    border-radius: 15px;
    transition: 0.15s;
    display: none !important;
  }

  &:hover::-webkit-scrollbar {
    display: block !important;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $black-400;
    border-radius: 15px;
    transition: 0.15s;

    &:hover {
      background-color: $black-500;
      width: 8px;
    }
  }
}

.sidebar-hint {
  width: 66px !important;
}
.sidebar-mobile {
  height: 100%;
  top: 0px !important;
}

.sidebar-hint-mobile {
  top: 0px;
  left: -240px !important;
}

.sidebar-header {
  font-size: 16px;
  padding: 8px !important;
  line-height: 18px;
  margin: 8px 0;
  // color: var(--brClr) !important;
}

.sidebar-name {
  font-size: 50px;
}

.active {
  color: $primary !important;
  font-weight: 600;

  span {
    font-weight: bolder !important;
  }
}

.active-icon {
  fill: $primary !important;
}

.nav-link {
  padding-top: 13px;
  padding-bottom: 13px;

  &:hover {
    background-color: $black-200;
  }
}

.state-menu-hint {
  cursor: pointer;
  transition: 150ms;
  border-radius: 4px;
  position: relative;

  &:hover {
    background-color: $black-300;
  }

  &:active {
    background-color: $black-400;
  }
}

.menu-hint {
  width: max-content;
  position: absolute;
  left: 66px !important;
  top: 0;
  height: fit-content;
  z-index: 99;

  .nav-menu-link {
    height: 44px;
    text-decoration: none;

    &:hover {
      background-color: $black-300;
    }

    &:active {
      background-color: $black-400;
    }
  }
}

.nav-overlay {
  position: fixed;
  // background-color: #1515153d;
  width: calc(100% - 66px);
  height: 100%;
  // z-index: 98;
  right: 0;
  bottom: 0;
}

.nav-hint {
  z-index: 99;
}

// Mobile
.mobile {
  position: fixed;
  left: -100%;
  top: 0;
  height: 100%;
  z-index: 9999;
}
</style>
