<template>
  <div v-if="checklogin == true">
    <Navbar
      @action="checkLogin()"
      @hintMenu="hindNav($event, false)"
      @hintMenuMobile="hindNav(false, true)"
    />
    <div class="container-fluid">
      <div class="d-flex justify-content-between position-relative">
        <Sidebar
          v-if="pathname != 'systemword'"
          :class="
            device != 'mobile' && device != 'tablet'
              ? !hint
                ? ''
                : 'sidebar-hint'
              : hintMobile
              ? 'sidebar-hint-mobile'
              : 'sidebar-mobile'
          "
          :hintSidebar="device != 'mobile' && device != 'tablet' ? !hint : true"
          @hintMenuMobile="hindNav(true, true)"
        />
        <div
          class="overlay-mobile"
          v-if="!hintMobile"
          @click="hindNav(true, true)"
        ></div>

        <main
          :class="
            device != 'mobile' && device !== 'tablet'
              ? !hint
                ? 'pt-5 px-xxxl-5 px-lg-5 px-md-3 px-sm-3 position-relative main-content'
                : 'pt-5 px-xxxl-5 px-lg-5 px-md-3 px-sm-3 position-relative main-content-hint'
              : 'pt-3 position-relative w-100'
          "
        >
          <router-view></router-view>
        </main>
      </div>
    </div>
  </div>
  <!-- <Login
    @action="checkLogin()"
    v-if="checklogin == false || checklogin == null"
  /> -->
  <!-- <router-view></router-view> -->
</template>

<script>
// import Login from "./views/Login.vue";
import Navbar from "./components/navbar/topNavbar.vue";
import Sidebar from "./components/navbar/leftNavbar.vue";
import AdsInventories from "@/typescript/inventory";
import VueCookies from "vue-cookies";
import cookiesPath from "./typescript/urlapi/cookiesPath";

export default {
  data() {
    return {
      hint: false,
      hintMobile: true,
      checklogin: true,
      pathname: "",
    };
  },
  components: {
    // Login,
    Navbar,
    Sidebar,
  },
  mounted() {
    setTimeout(() => {
      this.pathname = location.pathname?.split("/")[2];
      if (this.pathname == "systemword") {
        this.hint = true;
      }
    }, 1000);
    this.checkSection();
  },
  methods: {
    async checkLogin() {
      this.userInfo = VueCookies.get("UserInfo"); //JSON.parse(localStorage.getItem("UserInfo"));
      AdsInventories.userinfo = this.userInfo;
      
      if (
        this.userInfo == null ||
        (this.userInfo.intMemberID == null && this.userInfo.token == "")
      ) {
        this.checklogin = false;
       
        window.location.assign(
          cookiesPath == "localhost"
            ? "http://localhost:8080"
            : "https://cessystem.wu.ac.th/landingdev"
        );
        // this.loginPage();
      } else {
        this.checklogin = true;
      }
    },
    hindNav(hintMenu, blnMobile) {
      if (blnMobile === true) {
        this.hintMobile = hintMenu;
      } else this.hint = hintMenu;
    },
    checkSection() {
      let module = VueCookies.get("module");
      if (module == null) {
        // ถ้ายังไม่เคยเก็บคุ้กกี้ 'module' ให้เพิ่มเข้าไปใหม่
        VueCookies.set(
          "module",
          JSON.stringify({ COO: true }),
          "1d",
          "/",
          this.strCookiename,
          true,
          "None"
        );
      } else {
        // ถ้ายังคยเก็บคุ้กกี้ 'module' แล้วให้แอดโมดูลนี้เข้าไป
        module[this.strModuleName] = true;

        VueCookies.set(
          "module",
          JSON.stringify(module),
          "1d",
          "/",
          this.strCookiename,
          true,
          "None"
        );
      }

      const sessionTimeoutInMinutes = 6000; // Set your session timeout in minutes
      const sessionTimeoutInMillis = sessionTimeoutInMinutes * 60 * 1000; // Convert to milliseconds

      const checkSession = () => {
        // Perform a check here to see if the session has expired
        // If it has expired, you can log out the user or perform other actions
        // For simplicity, let's just log a message here
        let module = VueCookies.get("module");
        // ถ้าโมดูลนี้เป็น true ให้เปลี่ยนเป็น false เพราะเกินเวลา
        if (module[this.strModuleName] == true) {
          module[this.strModuleName] = false;
          // และ set cookie ใหม่
          VueCookies.set(
            "module",
            JSON.stringify(module),
            "1d",
            "/",
            this.strCookiename,
            true,
            "None"
          );
        }
        let intcheck = 0;
        for (const key in module) {
          if (module[key] == true) {
            intcheck++;
          }
        }
        // ทุกโมดูลเป็น false หมด
        if (intcheck == 0) {
          // VueCookies.remove("UserInfo");
          // VueCookies.remove("module");
          // this.$router.push("/login");
          // this.$router.go();
        }
        console.log(module)
      };

      const resetSessionTimer = () => {
        // Clear the previous timer and start a new one
        clearTimeout(this.sessionTimer);
        this.sessionTimer = setTimeout(checkSession, sessionTimeoutInMillis);
        let module = VueCookies.get("module");
        // ถ้าโมดูลนี้เป็น false ให้เปลี่ยนเป็น true เพราะเกินเวลา
        if (module[this.strModuleName] == false) {
          module[this.strModuleName] = true;
          // และ set cookie ใหม่
          VueCookies.set(
            "module",
            JSON.stringify(module),
            "1d",
            "/",
            this.strCookiename,
            true,
            "None"
          );
        }
      };

      // Initially start the timer
      resetSessionTimer();

      // Reset the timer whenever there's user activity, e.g., with a mousemove event
      window.addEventListener("mousemove", resetSessionTimer);
    },
    handleBeforeUnload() {
      let module = VueCookies.get("module");
      module[this.strModuleName] = false;
      VueCookies.set(
        "module",
        JSON.stringify(module),
        "1d",
        "/",
        this.strCookiename,
        true,
        "None"
      );
      // Your code to execute before the user leaves the page
      // For example, you can display a confirmation message
      // event.returnValue = "Are you sure you want to leave this page?";
    },
  },
  created() {
    this.checkLogin();
  },
  beforeUnmount() {
    // Remove the event listener when the component is destroyed
    window.removeEventListener("beforeunload", this.handleBeforeUnload);
  },
  unmounted() {
    window.removeEventListener("beforeunload", this.handleBeforeUnload);
  },
};
</script>
<style lang="scss">
@import "@/assets/scss/_features.scss";
@import "@/assets/scss/_custom.scss";
@import "~bootstrap/scss/bootstrap.scss";
* {
  font-family: "Bai Jamjuree", sans-serif !important;
}
#app {
  color: $black;
  box-sizing: border-box;
}
body {
  background-color: #fbfbfb !important;
  // height: 100vh !important;
}
.main-content {
  transition: 0.2s;
  margin-left: 240px;
  width: calc(100% - 240px);
  // height: calc(100vh - 75px);
}
.main-content-hint {
  transition: 0.2s;
  margin-left: 66px;
  width: calc(100% - 66px);
  // height: calc(100vh - 75px);
}
/* width */
*::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  border-radius: 4px;
}

/* Track */
*::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
*::-webkit-scrollbar-thumb {
  background: rgb(184, 184, 184);
  border-radius: 25px;
}

/* Handle on hover */
*::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.overlay-mobile {
  background-color: rgba(0, 0, 0, 0.25);
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 9998;
}
</style>
