<template>
  <div class="logout rounded-2 bg-white p-3 shadow-sm" :class="classLogout">
    <div class="d-flex align-items-center">
      <Avatar
        class="avatar-fix"
        size="small"
        :image="
          userInfo.urlImageProfile != '' ? userInfo.urlImageProfile : avatar
        "
        :blnvalue="userInfo.urlImageProfile != '' ? false : true"
        :usertype="userInfo.intUsertypegroupID == 1 ? 'student' : 'officer'"
      />
      <div class="d-flex flex-column ms-3">
        <span class="name mb-1 fnt-title fnt-regular">{{ username }}</span>
        <!-- <router-link to="/login" class="nav-link p-0 text-secondary"> -->
        {{
          userInfo.intUsertypegroupID == 1
            ? $t("450")
            : userInfo.intUsertypegroupID == 2
            ? userInfo.intUserTypeID == 3
              ? $t("640")
              : $t("1101")
            : $t("452")
        }}
        <!-- </router-link> -->
      </div>
    </div>
    <hr class="border" />
    <button
      class="btn p-0 m-0 w-100 fnt-body"
      style="text-align: left !important"
      @click="onLogout()"
    >
      {{ $t("660") }}
    </button>
  </div>
</template>

<script>
import Avatar from "@/components/avatar/avatar.vue";
import AdsInventories from "@/typescript/inventory";
import VueCookies from "vue-cookies";
import cookiesPath from "@/typescript/urlapi/cookiesPath.ts";
export default {
  data() {
    return {
      userInfo: {},
    };
  },
  mounted() {
    this.userInfo = AdsInventories.userinfo;
  },
  methods: {
    onLogout() {
      // console.log(localStorage)
      // localStorage.setItem("LoginState", false);
      try {
        localStorage.removeItem("SelectedListCampus");
        localStorage.removeItem("UserInfo");
        localStorage.removeItem("language");
        //บันทึกชื่อผู้ใช้งานไปยัง store
        // localStorage.setItem("username", null);
        // localStorage.clear();
        // console.log(document.cookie);
        // document.cookie =
        //   "UserInfo" + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        // console.log(document.cookie);
        VueCookies.remove("UserInfo", "/", cookiesPath);
        VueCookies.remove("moduleAccess", "/", cookiesPath);
        // console.log(VueCookies.get("UserInfo"));
        //ไปยังหน้าแรก
        setTimeout(() => {
          // console.log("click logOut");
          window.location.href =
            cookiesPath == "localhost"
              ? "http://localhost:8080"
              : "https://cessystem.wu.ac.th";
          // if (VueCookies.get("UserInfo") == null) {
          this.$emit("action");
          // this.$router.push("/login");
          // this.$router.go();
          // }
        }, 300);
      } catch (e) {
        // console.log(e);
      }
    },
  },
  components: {
    Avatar,
  },
  computed: {
    username() {
      return this.$i18n.locale == "EN" || this.$i18n.locale == "en"
        ? AdsInventories.userinfo.strFirstNameEN +
            " " +
            AdsInventories.userinfo.strLastNameEN
        : AdsInventories.userinfo.strFirstNameTH +
            " " +
            AdsInventories.userinfo.strLastNameTH;
    },
  },
  props: {
    avatar: { type: String, default: "" },
    name: { type: String, default: "" },
    classLogout: { type: String, default: "" },
  },
};
</script>

<style lang="scss" scoped>
.logout {
  width: 307px;
}
.avatar {
  position: relative;
  border-radius: 50%;
  border: none !important;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border: none !important;
    border-radius: 50%;
  }
}
</style>