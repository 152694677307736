<template>
  <nav
    class="navbar navbar-expand-md position-sticky sticky-top sticky-right navbar-light border-bottom bg-white px-lg-2 pe-sm-3"
    :class="device == 'mobile' ? 'row' : ''"
  >
    <div v-if="device != 'mobile'" class="container-fluid" id="container-fluid">
      <div class="navbar-brand d-flex align-items-center">
        <button
          class="btn me-lg-3 me-sm-1 me-sm-0 btn-none-active"
          @click="hintSidebar()"
          v-if="device !== 'mobile' && device !== 'tablet'"
        >
          <Icon icon="ci:hamburger" width="18" height="18" />
        </button>
        <button
          class="btn me-lg-3 me-sm-1 me-sm-0 btn-none-active"
          @click="hintSidebarMobile()"
          v-if="device == 'mobile' || device == 'tablet'"
        >
          <Icon icon="ci:hamburger" width="18" height="18" />
        </button>
        <!-- <Button /> -->
        <a
          :href="
            cookiesPath == 'localhost'
              ? 'http://localhost:8080'
              : `https://${cookiesPath}/landing/`
          "
          class="logo navbar-brand"
        >
          <img
            src="@/assets/img/CESLOGOTEXT.png"
            alt=""
            :style="device == 'mobile' ? 'height:24px !important;' : ''"
          />
        </a>
        <!-- {{ device == "mobile" }} -->
        <div class="d-flex align-items-center">
          <Icon icon="akar-icons:chevron-right" class="text-primary" />
          <span
            class="ms-lg-2 ms-sm-1 p-0 m-0 text-primary module-name fnt-h6 fnt-medium"
            :class="device == 'mobile' ? 'fnt-body' : ''"
            >{{ $t("1") }}</span
          >
        </div>
      </div>
      <div class="nav-search d-none">
        <Search class="w-100" :hint="$t('13')" />
      </div>
      <div class="d-flex align-items-center">
        <Translate class="me-sm-1 me-lg-3" id="changeLanguage" />
        <!-- <Button
          icon="NotiOutline"
          class="border-0 p-0"
          width="20"
          height="20"
          :style="
            device == 'mobile' ? 'width:18px !important;margin-right:8px;' : ''
          "
        /> -->
        <!-- @click="onLogout()" -->
        <button
          @click="openLogout()"
          type="submit"
          class="btn p-0 ms-lg-3 ms-sm-3"
        >
          <div class="avatar">
            <img
              v-if="URLProfile != '' && URLProfile != null"
              :src="URLProfile"
              alt="..."
              id="ImageProfile"
            />
            <FindUserFill
              v-if="URLProfile == '' || URLProfile == null"
              class="fill-black-400"
            />
          </div>
        </button>
      </div>
    </div>
    <div v-if="device == 'mobile'" class="col-12" id="container-fluid">
      <div class="row container-fluid p-0 m-0">
        <!-- <div class="col-2 text-truncate">
          {{ $t("239") }}
        </div> -->
        <div class="d-flex align-items-center col-8 m-0 p-0">
          <button
            class="btn me-lg-3 me-sm-1 me-sm-0 btn-none-active"
            @click="hintSidebar()"
            v-if="device !== 'mobile' && device !== 'tablet'"
          >
            <Icon icon="ci:hamburger" width="18" height="18" />
          </button>
          <button
            class="btn me-lg-3 me-sm-1 me-sm-0 btn-none-active"
            @click="hintSidebarMobile()"
            v-if="device == 'mobile' || device == 'tablet'"
          >
            <Icon icon="ci:hamburger" width="18" height="18" />
          </button>
          <!-- <Button /> -->
          <router-link to="/" class="logo navbar-brand">
            <img
              src="@/assets/img/CESLOGOTEXT.png"
              alt=""
              :style="device == 'mobile' ? 'height:24px !important;' : ''"
            />
          </router-link>
          <!-- {{ device == "mobile" }} -->
          <transition name="move">
            <div
              class=""
              :class="
                blnclickHeading == true
                  ? 'bg-white heading-name w-100 rounded shadow-sm p-1'
                  : 'text-truncate'
              "
              :style="this.$i18n.locale == 'th' ? 'width: 40%;' : 'width: 47%;'"
              style="z-index: 1"
              @click="onClickHeading()"
            >
              <span
                class="ms-lg-2 ms-sm-1 p-0 m-0 text-primary module-name fnt-h6 fnt-body"
                :class="
                  blnclickHeading == true
                    ? 'd-flex flex-nowrap align-items-center text-nowrap'
                    : ''
                "
              >
                <Icon icon="akar-icons:chevron-right" class="text-primary" />
                {{ $t("1") }}
              </span>
            </div>
          </transition>

          <transition name="fade">
            <div
              class="overlay"
              v-if="blnclickHeading == true"
              @click="blnclickHeading = !blnclickHeading"
            ></div>
          </transition>
        </div>

        <div class="d-flex justify-content-end align-items-center px-1 col-4">
          <div class="d-flex">
            <Translate class="me-sm-1 me-lg-3" id="changeLanguage" />
            <Button
              icon="NotiOutline"
              class="border-0 p-0"
              width="20"
              height="20"
              :style="
                device == 'mobile'
                  ? 'width:18px !important;margin-right:8px;'
                  : ''
              "
            />
            <!-- @click="onLogout()" -->
            <button
              @click="openLogout()"
              type="submit"
              class="btn p-0 ms-lg-3 ms-sm-3"
            >
              <div class="avatar">
                <img
                  v-if="URLProfile != '' && URLProfile != null"
                  :src="URLProfile"
                  alt="..."
                  id="ImageProfile"
                />
                <FindUserFill
                  v-if="URLProfile == '' || URLProfile == null"
                  class="fill-black-400"
                />
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- <div v-if="$route.params.id == 4" class="progress" style="width: 50%"></div> -->
  </nav>
  <transition name="fade" :id="'LogoutOverlay'">
    <Logout
      v-if="logout == true"
      class="logout"
      name="Porawee Panams"
      :avatar="URLProfile"
    />
  </transition>
  <!-- <transition name="fade">
    <div class="overlay" v-show="logout == true" @click="logout = false"></div>
  </transition> -->
</template>

<script>
import Logout from "@/components/dialog/Logout.vue";
import Translate from "../input/toggle/translate.vue";
import { Icon } from "@iconify/vue";
import Search from "../../components/input/inputSearch.vue";
import Button from "../button/button.vue";
// import VueCookies from "vue-cookies"
import cookiesPath from "@/typescript/urlapi/cookiesPath";
import AdsInventories from "@/typescript/inventory";
import { FindUserFill } from "@/components/icon/index";
export default {
  components: {
    Icon,
    Search,
    Button,
    Translate,
    Logout,
    FindUserFill,
  },
  emits: ["action", "hintMenu"],
  data() {
    return {
      cookiesPath: cookiesPath,
      blnHintMenu: false,
      logout: false,
      // heightHeading: null,
      blnclickHeading: false,
      URLProfile:'',
    };
  },
  mounted() {
    // setInterval(() => {
    // var element = document.getElementById("container-fluid");
    // var positionInfo = element.getBoundingClientRect();
    // this.heightHeading = positionInfo.height;
    // console.log(this.heightHeading);
    // }, 1000);
    // setTimeout(() => {
      this.URLProfile = AdsInventories.userinfo.urlImageProfile
    // }, 1000);
    this.onClickOutSide();
    this.onClickUpdateIMGProfile();
  },
  // computed: {
  //   URLProfile() {
  //     return AdsInventories.userinfo.urlImageProfile;
  //   },
  // },
  methods: {
    // click Outside (Overlay)
    onClickOutSide() {
      const handleMouseDown = (e) => {
        let clickOutside = true;
        let el = e.target;
        while (el.parentElement) {
          if (el.id === "LogoutOverlay") {
            clickOutside = false;
          }
          el = el.parentElement;
        }
        if (clickOutside == true) {
          this.logout = false;
        }
      };
      document.addEventListener("mousedown", handleMouseDown);
    },
    onClickUpdateIMGProfile() {
      const handleMouseDown = (e) => {
        // let SubmitUserinfo = true;
        let el = e.target;
        while (el.parentElement) {
          if (el.id === "submitUserInfo1") {
            // SubmitUserinfo = false;
            setTimeout(() => {
              this.URLProfile = AdsInventories.userinfo.urlImageProfile;
            }, 3000);
          }
          el = el.parentElement;
        }
        // if (SubmitUserinfo == true) {
          // this.logout = false;
        // }
      };
      document.addEventListener("mousedown", handleMouseDown);
    },
    onClickHeading() {
      this.blnclickHeading = !this.blnclickHeading;
      // console.log(this.blnclickHeading);
    },
    openLogout() {
      // VueCookies.remove('UserInfo' , '/', 'localhost')
      this.logout = !this.logout;
      // console.log(this.logout);
    },
    // onLogout() {
    //   localStorage.setItem("LoginState", false);

    //   //บันทึกชื่อผู้ใช้งานไปยัง store
    //   localStorage.setItem("username", null);
    //   localStorage.clear();
    //   //ไปยังหน้าแรก
    //   this.$emit("action");
    // },
    hintSidebar() {
      this.blnHintMenu = !this.blnHintMenu;
      this.$emit("hintMenu", this.blnHintMenu);
    },
    hintSidebarMobile() {
      this.blnHintMenu = !this.blnHintMenu;
      this.$emit("hintMenuMobile", this.blnHintMenu);
    },
  },
};
</script>

<style lang="scss" scoped>
.logout {
  position: fixed;
  top: calc(67px + 16px);
  right: 24px;
  z-index: 9999;
}
.navbar {
  position: relative;

  .nav-search {
    width: 500px !important;
  }
  .logo {
    img {
      height: 38px !important;
    }
  }
  .avatar {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    position: relative;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
.overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: transparent;
  z-index: 9998;
  left: 0;
  bottom: 0;
}
.heading-name {
  transform: scale(1.5);
  // transform: translateY(50%);
  transition: all 0.2s;
}

.move-enter-active {
  animation: move-in 0.3s;
}
.move-leave-active {
  animation: move-in 0.3s reverse;
}
@keyframes move-in {
  0% {
    transform: scale(1);
    opacity: 0;
  }
  100% {
    transform: scale(1.5);
    opacity: 1;
  }
}
</style>
