<template>
  <div class="d-flex">
    <div class="locale-changer" :class="device == 'mobile'?'d-flex':''">
      <!-- ปุ่มเปลี่ยนภาษาอังกฤษ -->
      <button
        class="btn rounded-0 fnt-medium"
        :class="locales == 'th' ? 'active' : ''"
        :style="
          device == 'mobile'
            ? 'font-size:9px !important;'
            : 'font-size:12px !important;'
        "
        @click="changeLanguage('th')"
      >
        TH
      </button>
      <button
        class="btn rounded-0 fnt-medium"
        :class="locales == 'en' ? 'active' : ' '"
        @click="changeLanguage('en')"
        :style="
          device == 'mobile'
            ? 'font-size:9px !important;'
            : 'font-size:12px !important;'
        "
      >
        EN
      </button>
      <!-- ปุ่มเปลี่ยนภาษาไทย -->
    </div>
  </div>
  
</template>

<script>
import VueCookies from "vue-cookies";
import cookiesPath from '@/typescript/urlapi/cookiesPath.ts'
export default {
  name: "locale-changer",

  data() {
    return {
      locales: null,
    };
  },
  mounted() {
    this.locales = VueCookies.get("language")??'th';
    // console.log(this.locales)
    VueCookies.set("language", this.locales, '1d', '/', cookiesPath, true, 'None');
    this.$i18n.locale = this.locales;
  },
  methods: {
    //ฟังก์ชันเปลี่ยนภาษา
    changeLanguage(event) {
      if (this.$i18n.locale !== event) {
        //reload เว็บ
        // this.$router.go();
        //บันทึกข้อมูลไปยัง local
        VueCookies.set("language", event, '1d', '/', cookiesPath, true, 'None');
        localStorage.setItem("language", event);
        this.locales = event;
        this.$i18n.locale = event;
      }
    },
  },
  //เรียกข้อมูลการตั้งค่าภาษาตอนเปิดหน้าเว็บ
  beforeMount() {
    //เรียกข้อมูลจาก local
    this.locales = localStorage.getItem("language");
    //ถ้าไม่เจอค่า
    if (this.locales == null || this.locales == "") {
      //ตั้งค่าเริ่มต้นเป็นภาษาอังกฤษ
      this.locales = "en";
      this.$i18n.locale = this.locales;
    }
    //ถ้าเจอข้อมูล
    else {
      //ตั้งค่าภาษาตามข้อมูลที่เรียกได้
      this.$i18n.locale = this.locales;
    }
  },
};
</script>

<style lang="scss" scoped>
.active {
  background-color: $primary;
  color: $white;
  font-weight: 600;
  &:hover {
    color: $white;
  }
}
</style>