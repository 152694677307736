<template>
  <div class="container-fluid p-8 bg-white statuscode">
    <nav class="position-absolute top-0 left-0 pt-8">
      <div class="logo">
        <img src="@/assets/img/CESLOGOTEXT.png" alt="" />
      </div>
    </nav>

    <div class="row status">
      <div class="col-6 mb-9 status-msg col-xs-12">
        <span class="fnt-regular text-primary">{{ detail }}</span>
      </div>
      <div
        class="col-6 mb-9 col-xs-12 status-code text-center d-flex flex-column align-items-center"
      >
        <span class="status-code-number text-primary">{{ codeNumber }}</span>
        <span class="status-code-msg text-primary">{{ message }}</span>
        <Button
          name="Go to homepage"
          classBtn="btn-primary status-code-btn "
          class="mt-5"
          @click="goToLanding()"
        />
      </div>
    </div>

    <div class="bg-wave">
      <svg
        width="1920"
        height="544"
        viewBox="0 0 1920 544"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M0 543.199V282.074C101.602 231.331 258.267 183.199 523.5 183.199C779.626 183.199 900.488 237.641 1008.06 286.096C1120.14 336.583 1217.8 380.571 1439 349.699C1668.59 317.657 1824.53 163.596 1920 0V543.199H0Z"
          fill="#6D3DAA"
        />
      </svg>
    </div>
  </div>
</template>

<script>
import Button from "../button/button.vue";
export default {
  components: {
    Button,
  },
  props: {
    detail: { type: String },
    codeNumber: { type: Number },
    message: { type: String },
  },
  methods: {
    goToLanding() {
      window.location.href = "https://cessystem.wu.ac.th/landing";
    },
  },
};
</script>

<style lang="scss" scoped>
.statuscode {
  width: 100%;
  height: 100vh;
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  .status {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    &-msg {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 1.77083333vw;
    }
    &-code {
      &-btn {
        font-size: 1.875vw !important;
        z-index: 99;
      }
      &-number {
        font-size: 15vw;
        line-height: calc(15vw / 1.05);
      }
      &-msg {
        font-size: 3.75vw;
      }
    }
  }
  nav {
    .logo {
      max-width: 157px;
      max-height: 83px;
      position: relative;
      img {
        width: 100%;
        height: 100%;
        position: relative;
        object-fit: cover;
      }
    }
  }
  .bg-wave {
    position: absolute;
    left: 0;
    bottom: 0;
    max-height: 543px;
    width: 100%;
    svg {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
</style>
