export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผู้ดูแลระบบ"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วิทยาเขต"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["หน่วยงาน"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เขตข้อมูลผู้ใช้"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เทมเพลต"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผู้ใช้"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ตำแหน่ง"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ระดับปริญญา"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ประเภทผู้ใช้"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คำนำหน้าชื่อ"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["พารามิเตอร์"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["หน่วยงานวิชาการ"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ค้นหา"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ค้นหาวิทยาเขต"])},
  "15": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("first")), "-", _interpolate(_named("last")), " จาก ", _interpolate(_named("total")), " รายการ"])},
  "16": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), "/หน้า"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไปยังหน้า"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ลำดับ"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สถานะ"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เพิ่มวิทยาเขต"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วิทยาเขตหลัก"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ตัวกรอง"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การตั้งค่าการแสดงผล"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ข้อมูลนี้มีการเชื่อมโยงกับข้อมูลอื่น ไม่สามารถลบได้"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ข้อมูลนี้เป็นวิทยาเขตหลัก ไม่สามารถลบได้ กรุณาเปลี่ยนวิทยาเขตนี้เป็นวิทยาเขตย่อยก่อนทำการลบ"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การเพิ่มข้อมูลสำเร็จ"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การแก้ไขข้อมูลสำเร็จ"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การลบข้อมูลสำเร็จ"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่พบข้อมูลวิทยาเขต"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กรุณากรอกข้อมูลวิทยาเขต"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ข้อมูลวิทยาเขต"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ข้อมูลติดต่อ"])},
  "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ตราสัญลักษณ์"])},
  "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["จำเป็น*"])},
  "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ยกเลิก"])},
  "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ตกลง"])},
  "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กรุณากรอกข้อมูลชื่อวิทยาเขต"])},
  "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กรุณากรอกข้อมูลชื่อย่อวิทยาเขต"])},
  "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กรุณากรอกข้อมูลที่อยู่วิทยาเขต"])},
  "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กรุณากรอกข้อมูลเบอร์โทรศัพท์"])},
  "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กรุณากรอกข้อมูลอีเมล"])},
  "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กรุณากรอกข้อมูลบัญชีเฟซบุ๊ก"])},
  "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กรุณากรอกข้อมูลหมายเลขแฟกซ์"])},
  "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กรุณากรอกข้อมูลเว็บไซต์"])},
  "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กรุณากรอกข้อมูลเป็นภาษาไทย"])},
  "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กรุณากรอกข้อมูลเป็นภาษาอังกฤษ"])},
  "47": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กรุณากรอก (A-Z) (a-z) (0-9) (ก-ฮ)"])},
  "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชื่อวิทยาเขตนี้มีอยู่ในระบบแล้ว กรุณาใช้ชื่ออื่น"])},
  "49": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชื่อย่อวิทยาเขตนี้มีอยู่ในระบบแล้ว กรุณาใช้ชื่ออื่น"])},
  "50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ที่อยู่วิทยาเขตนี้มีอยู่ในระบบแล้ว กรุณาใช้ที่อยู่วิทยาเขตอื่น"])},
  "51": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เบอร์โทรศัพท์นี้มีอยู่ในระบบแล้ว กรุณาใช้เบอร์โทรศัพท์อื่น"])},
  "52": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["อีเมลนี้มีอยู่ในระบบแล้ว กรุณาใช้อีเมลอื่น"])},
  "53": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["บัญชีเฟซบุ๊กนี้มีอยู่ในระบบแล้ว กรุณาใช้บัญชีเฟซบุ๊กอื่น"])},
  "54": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["หมายเลขแฟกซ์นี้มีอยู่ในระบบแล้ว กรุณาใช้หมายเลขแฟกซ์อื่น"])},
  "55": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เว็บไซต์นี้มีอยู่ในระบบแล้ว กรุณาใช้เว็บไซต์อื่น"])},
  "56": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กรุณากรอกเฉพาะตัวเลข (0-9) "])},
  "57": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กรุณากรอกอีเมลตามรูปแบบ name", "@", "email.com ห้ามเว้นวรรค"])},
  "58": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กรุณากรอกบัญชีเฟซบุ๊กตามรูปแบบ https://www.facebook.com/me ห้ามเว้นวรรค"])},
  "59": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กรุณากรอกเว็บไซต์ตามรูปแบบ https://www.website.ac.th/ ห้ามเว้นวรรค"])},
  "60": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คุณต้องการลบวิทยาเขตนี้ ใช่หรือไม่"])},
  "61": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เปิด"])},
  "62": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ปิด"])},
  "63": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชื่อวิทยาเขต (อังกฤษ)"])},
  "64": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชื่อย่อวิทยาเขต (อังกฤษ)"])},
  "65": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ที่อยู่วิทยาเขต (อังกฤษ)"])},
  "66": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชื่อวิทยาเขต (ไทย)"])},
  "67": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชื่อย่อวิทยาเขต (ไทย)"])},
  "68": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ที่อยู่วิทยาเขต (ไทย)"])},
  "69": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เบอร์โทรศัพท์"])},
  "70": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["อีเมล"])},
  "71": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เฟซบุ๊ก"])},
  "72": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แฟกซ์"])},
  "73": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เว็บไซต์"])},
  "74": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วิทยาเขตย่อย"])},
  "75": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ข้อมูลทั้งหมด"])},
  "76": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ข้อมูลที่แสดง"])},
  "77": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เลือกทั้งหมด"])},
  "78": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ล้างทั้งหมด"])},
  "79": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ทั้งหมด"])},
  "80": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คืนค่า"])},
  "81": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่มีข้อมูล"])},
  "82": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ค้นหาตำแหน่ง"])},
  "83": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เพิ่มตำแหน่ง"])},
  "84": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชื่อตำแหน่ง (อังกฤษ)"])},
  "85": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชื่อตำแหน่ง (ไทย)"])},
  "86": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ระยะเวลาการดำรงตำแหน่ง"])},
  "87": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่พบข้อมูลตำแหน่ง"])},
  "88": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คุณต้องการลบตำแหน่งนี้ ใช่หรือไม่"])},
  "89": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กำลังใช้งาน"])},
  "90": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่ได้ใช้งาน"])},
  "91": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ปี"])},
  "92": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เดือน"])},
  "93": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ปี"])},
  "94": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เดือน"])},
  "95": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["ไม่พบข้อมูล ", _interpolate(_named("fieldname"))])},
  "96": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ค้นหาประเภทผู้ใช้"])},
  "97": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เพิ่มประเภทผู้ใช้"])},
  "98": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คุณต้องการลบประเภทผู้ใช้นี้ ใช่หรือไม่"])},
  "99": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชื่อประเภทผู้ใช้ (อังกฤษ)"])},
  "100": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชื่อประเภทผู้ใช้ (ไทย)"])},
  "101": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่มีข้อมูลที่ถูกจัดกลุ่ม จึงไม่สามารถใช้งานตัวกรองได้"])},
  "102": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ค้นหาระดับปริญญา"])},
  "103": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เพิ่มระดับปริญญา"])},
  "104": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คุณต้องการลบระดับปริญญานี้ ใช่หรือไม่"])},
  "105": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชื่อระดับปริญญา (อังกฤษ)"])},
  "106": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชื่อระดับปริญญา (ไทย)"])},
  "107": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คำนำหน้าชื่อ (อังกฤษ)"])},
  "108": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คำนำหน้าชื่อ (ไทย)"])},
  "109": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชื่อย่อคำนำหน้าชื่อ (อังกฤษ)"])},
  "110": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชื่อย่อคำนำหน้าชื่อ (ไทย)"])},
  "111": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เพิ่มคำนำหน้าชื่อ"])},
  "112": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ค้นหาคำนำหน้าชื่อ"])},
  "113": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คุณต้องการลบคำนำหน้าชื่อนี้ ใช่หรือไม่"])},
  "114": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ค้นหาพารามิเตอร์"])},
  "115": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เพิ่มพารามิเตอร์"])},
  "116": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชื่อพารามิเตอร์"])},
  "117": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คำอธิบาย"])},
  "118": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ค่าพารามิเตอร์"])},
  "119": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ประเภทค่าพารามิเตอร์"])},
  "120": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ข้อความ"])},
  "121": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ตัวเลข"])},
  "122": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["บูลีน"])},
  "123": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สกุลเงิน"])},
  "124": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เพิ่มเขตข้อมูลผู้ใช้"])},
  "125": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ค้นหาเขตข้อมูลผู้ใช้"])},
  "126": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชื่อเขตข้อมูล (อังกฤษ)"])},
  "127": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ประเภทเขตข้อมูล"])},
  "128": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การเชื่อมโยง"])},
  "129": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ประเภทข้อมูล"])},
  "130": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ค่าข้อมูล"])},
  "131": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["จำเป็นต้องมี"])},
  "132": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การระบุตัวตน"])},
  "133": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กำหนดได้มากกว่า 1 ครั้ง ในเทมเพลตเดียวกัน"])},
  "134": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["จำเป็นต้องมีค่าในเขตข้อมูล"])},
  "135": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผู้ใช้กำหนดเอง"])},
  "136": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Control"])},
  "137": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["หลายตัวเลือก"])},
  "138": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ค่าเฉพาะ"])},
  "139": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วันที่"])},
  "140": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เวลา"])},
  "141": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่จำเป็น"])},
  "142": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่ระบุตัวตน"])},
  "143": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ทำซ้ำไม่ได้"])},
  "144": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เป็นค่าว่าง"])},
  "145": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คุณต้องการลบเขตข้อมูลผู้ใช้นี้ ใช่หรือไม่"])},
  "146": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วันที่/เวลา"])},
  "147": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ระบุตัวตน"])},
  "148": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ทำซ้ำได้"])},
  "149": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ข้อมูลนี้เป็นเขตข้อมูลแบบควบคุม ไม่สามารถลบได้"])},
  "150": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชื่อเทมเพลต (อังกฤษ)"])},
  "151": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เพิ่มเทมเพลต"])},
  "152": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ค้นหาเทมเพลต"])},
  "153": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กำลังใช้งาน"])},
  "154": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ค่าเริ่มต้น"])},
  "155": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ค้นหาหน่วยงาน"])},
  "156": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เพิ่มหน่วยงาน"])},
  "157": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชื่อหน่วยงาน (อังกฤษ)"])},
  "158": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชื่อย่อหน่วยงาน (อังกฤษ)"])},
  "159": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ที่อยู่หน่วยงาน (อังกฤษ)"])},
  "160": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชื่อหน่วยงาน (ไทย)"])},
  "161": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชื่อย่อหน่วยงาน (ไทย)"])},
  "162": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ที่อยู่หน่วยงาน (ไทย)"])},
  "163": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ข้อมูลนี้เป็นเทมเพลตค่าเริ่มต้น ไม่สามารถลบได้ กรุณาเปลี่ยนเทมเพลตอื่นให้เป็นเทมเพลตค่าเริ่มต้นก่อนทำการลบ"])},
  "164": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ค้นหาหน่วยงานวิชาการ"])},
  "165": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เพิ่มหน่วยงานวิชาการ"])},
  "166": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชื่อหน่วยงานวิชาการ (อังกฤษ)"])},
  "167": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชื่อย่อหน่วยงานวิชาการ (อังกฤษ)"])},
  "168": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชื่อหน่วยงานวิชาการ (ไทย)"])},
  "169": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชื่อย่อหน่วยงานวิชาการ (ไทย)"])},
  "170": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ระหว่างดำเนินการ"])},
  "171": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ระงับการใช้งาน"])},
  "172": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ข้อมูลนี้เพิ่งถูกใช้งาน ไม่สามารถลบได้"])},
  "173": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เกิดข้อผิดพลาดภายในเซิร์ฟเวอร์"])},
  "174": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["พบข้อผิดพลาด(API) โปรดลองใหม่อีกครั้ง"])},
  "175": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่พบหน้านี้"])},
  "176": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["ไม่พบ ", _interpolate(_named("URL")), " ที่เรียกใช้ในเซิร์ฟเวอร์นี้"])},
  "177": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไปยังหน้าแรก"])},
  "178": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กรุณากรอกชื่อผู้ใช้และรหัสผ่านให้ถูกต้อง"])},
  "179": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กำลังโหลดข้อมูล กรุณารอสักครู่"])},
  "180": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รหัสผู้ใช้"])},
  "181": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชื่อ (อังกฤษ)"])},
  "182": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["นามสกุล (อังกฤษ)"])},
  "183": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชื่อ, นามสกุล"])},
  "184": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชื่อ"])},
  "185": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["นามสกุล"])},
  "186": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["คุณต้องการลบ", _interpolate(_named("fieldname")), "นี้ ใช่หรือไม่"])},
  "187": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การตั้งค่าข้อมูลพื้นฐาน"])},
  "188": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ตัวเลือก"])},
  "189": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ค่าข้อมูล"])},
  "190": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รายการค่าข้อมูล"])},
  "191": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ค้นหา"])},
  "192": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชื่อเขตข้อมูล (ไทย)"])},
  "193": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กรุณากรอกเขตข้อมูลผู้ใช้"])},
  "194": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กรุณากรอกชื่อเขตข้อมูล"])},
  "195": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชื่อเขตข้อมูลนี้มีอยู่ในระบบแล้ว กรุณาใช้ชื่ออื่น"])},
  "196": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["พบข้อผิดพลาด คุณต้องการเพิ่มเขตข้อมูลผู้ใช้นี้ ใช่หรือไม่"])},
  "197": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["พร้อมใช้งาน"])},
  "198": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่พร้อมใช้งาน"])},
  "199": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กรุณากรอก (A-Z) (a-z) (0-9) (ก-ฮ) (/ . () - )"])},
  "200": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["กรุณากรอกข้อมูล", _interpolate(_named("fieldname"))])},
  "201": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["ข้อมูล", _interpolate(_named("fieldname"))])},
  "202": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["กรุณากรอกข้อมูล", _interpolate(_named("fieldname"))])},
  "203": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รูปภาพ"])},
  "204": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["หน่วยงานนี้มีอยู่ในระบบแล้ว คุณต้องการเพิ่ม ใช่หรือไม่"])},
  "205": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["หน่วยงานวิชาการนี้มีอยู่ในระบบแล้ว คุณต้องการเพิ่ม ใช่หรือไม่"])},
  "206": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กลุ่มข้อมูล"])},
  "207": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เขตข้อมูล"])},
  "208": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["หัวเรื่อง 1"])},
  "209": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["หัวเรื่อง 2"])},
  "210": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["หัวเรื่อง 3"])},
  "211": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วางเขตข้อมูลที่นี่"])},
  "212": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่พบกลุ่มข้อมูล"])},
  "213": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่พบเขตข้อมูล"])},
  "214": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ใช่"])},
  "215": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่ใช่"])},
  "216": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คุณต้องการยกเลิกการบันทึกข้อมูลหรือไม่"])},
  "217": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วิทยาเขตหลักกำลังใช้งาน ไม่สามารถเปลี่ยนประเภทวิทยาเขตนี้ได้ กรุณาแก้ไขวิทยาเขตย่อยเป็นวิทยาเขตหลัก"])},
  "218": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ยืนยัน"])},
  "219": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วัน"])},
  "220": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["รูปภาพนี้มีขนาดใหญ่เกินไป ขนาดรูปภาพสูงสุดต้องไม่เกิน ", _interpolate(_named("filesize")), " MB"])},
  "221": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("fieldname")), "นี้มีอยู่ในระบบแล้ว กรุณาใช้ชื่ออื่น"])},
  "222": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ข้อมูลนี้เพิ่งถูกลบไป ไม่สามารถแก้ไขได้"])},
  "223": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["พบข้อผิดพลาด(เว็บไซต์) ระบบกำลังกลับสู่หน้าแรก"])},
  "224": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เขตข้อมูลนี้จำเป็นต้องมี ไม่สามารถลบได้"])},
  "225": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กลุ่มของเขตข้อมูล"])},
  "226": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เขตข้อมูลแบบควบคุม"])},
  "227": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ข้อมูลนี้เพิ่งถูกใช้งาน ไม่สามารถแก้ไขได้"])},
  "228": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ข้อมูลนี้เป็นเขตข้อมูลแบบควบคุม ไม่สามารถแก้ไขได้"])},
  "229": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["รูปภาพมีขนาดใหญ่เกินไป ขนาดรูปภาพสูงสุดต้องไม่เกิน ", _interpolate(_named("width")), " x ", _interpolate(_named("height")), "."])},
  "230": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กรุณาเลือกข้อมูลประเภทรูปภาพเท่านั้น (.jpeg, .png)"])},
  "231": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กรุณากรอก (A-Z) (a-z) (เว้นวรรค) (/ . () _ -)"])},
  "232": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กรุณากรอก (ก-ฮ) (เว้นวรรค) (/ . () _ -)"])},
  "233": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชื่อวิทยาเขต"])},
  "234": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กรุณากรอก (A-Z) (a-z) (0-9) (/ . () - ,)"])},
  "235": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กรุณากรอก (ก-ฮ) (0-9) (/ . () - ,)"])},
  "236": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ค้นหาไม่ได้"])},
  "237": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เพิ่ม"])},
  "238": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แก้ไข"])},
  "239": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ระบบเปิดรายวิชา"])},
  "240": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["จำนวนผู้เรียนต่อกลุ่ม"])},
  "241": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ค้นหาจำนวนผู้เรียนต่อกลุ่ม"])},
  "242": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เพิ่มจำนวนผู้เรียนต่อกลุ่ม"])},
  "243": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["จำนวนผู้เรียนต่อกลุ่ม (คน)"])},
  "244": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กรุณากรอกข้อมูลจำนวนผู้เรียนต่อกลุ่ม"])},
  "245": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ข้อมูลจำนวนผู้เรียนต่อกลุ่ม"])},
  "247": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ค้นหาประเภทห้องเรียน"])},
  "248": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เพิ่มประเภทห้องเรียน"])},
  "249": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ประเภทห้องเรียน"])},
  "250": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ประเภทห้องเรียน (อังกฤษ)"])},
  "251": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["จำนวนที่นั่ง"])},
  "252": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กรุณากรอกข้อมูลประเภทห้องเรียน"])},
  "253": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ข้อมูลประเภทห้องเรียน"])},
  "254": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ค้นหาประเภทการสอน/รูปแบบการสอน"])},
  "255": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ประเภทการสอน"])},
  "256": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ประเภทการสอน (อังกฤษ)"])},
  "257": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เพิ่มประเภทการสอน"])},
  "258": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กรุณากรอกข้อมูลประเภทการสอน"])},
  "259": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ข้อมูลประเภทการสอน"])},
  "260": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ค้นหาประเภทการสอน/รูปแบบการสอน"])},
  "261": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รูปแบบการสอน"])},
  "262": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รูปแบบการสอน (อังกฤษ)"])},
  "263": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เพิ่มรูปแบบการสอน"])},
  "264": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กรุณากรอกข้อมูลรูปแบบการสอน"])},
  "265": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ข้อมูลรูปแบบการสอน"])},
  "266": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การสอบ"])},
  "267": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ค้นหาประเภทการสอบ/ระยะเวลาการสอบ"])},
  "268": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ประเภทการสอบ"])},
  "269": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ประเภทการสอบ (อังกฤษ)"])},
  "270": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เพิ่มประเภทการสอบ"])},
  "271": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กรุณากรอกข้อมูลประเภทการสอบ"])},
  "272": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ข้อมูลประเภทการสอบ"])},
  "273": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ค้นหาประเภทการสอบ/ระยะเวลาการสอบ"])},
  "274": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ระยะเวลาการสอบ"])},
  "275": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ระยะเวลาการสอบ (อังกฤษ)"])},
  "276": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["นาที"])},
  "277": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เพิ่มระยะเวลาการสอบ"])},
  "278": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กรุณากรอกข้อมูลระยะเวลาการสอบ"])},
  "279": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ข้อมูลระยะเวลาการสอบ"])},
  "280": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ข้อมูลผู้ใช้"])},
  "281": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คุณต้องการเปลี่ยนประเภทผู้ใช้ ใช่หรือไม่"])},
  "282": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คุณต้องการเปลี่ยนเทมเพลต ใช่หรือไม่"])},
  "283": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ยืนยัน"])},
  "284": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["กรุณาเลือกข้อมูล", _interpolate(_named("fieldname"))])},
  "285": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กรุณากรอกข้อมูลผู้ใช้"])},
  "286": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ข้อมูลผู้ใช้"])},
  "287": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เพิ่มข้อมูลผู้ใช้"])},
  "288": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["จัดการข้อมูลหลักสูตร"])},
  "289": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ข้อมูลหลักสูตร"])},
  "290": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เพิ่มข้อมูลหลักสูตร"])},
  "291": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กรุณากรอกข้อมูลหลักสูตร"])},
  "292": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ฉบับ"])},
  "293": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ปีที่จัดทำ"])},
  "294": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รหัสหลักสูตร"])},
  "295": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชื่อหลักสูตร"])},
  "296": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชื่อหลักสูตร (อังกฤษ)"])},
  "297": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชื่อเต็มปริญญา"])},
  "298": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชื่อเต็มปริญญา (อังกฤษ)"])},
  "299": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชื่อย่อปริญญา"])},
  "300": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชื่อย่อปริญญา (อังกฤษ)"])},
  "301": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ระดับปริญญา"])},
  "302": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ประเภทของหลักสูตร"])},
  "303": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ภาษาที่ใช้"])},
  "304": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การจัดการศึกษา"])},
  "305": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["อักษรย่อหลักสูตร"])},
  "306": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["หน่วยการศึกษา"])},
  "307": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่สามารถเรียกข้อมูลได้"])},
  "308": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คุณไม่ได้กรอกข้อมูลรหัสหลักสูตร หลักสูตรจะเปิดใช้งานได้เพียงชั่วคราวเท่านั้น"])},
  "309": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["หลังจากได้รับรหัสหลักสูตรเรียบร้อยแล้ว กรุณากรอกข้อมูลให้ครบถ้วน"])},
  "310": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["ข้อมูล ", _interpolate(_named("ProgramtitleTH")), " ", _interpolate(_named("ProgramtitleEN")), " ", _interpolate(_named("version")), " ", _interpolate(_named("Year")), " มีอยู่ในระบบแล้ว กรุณาแก้ไขข้อมูลใหม่"])},
  "311": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชื่อย่อหลักสูตรต้องเป็นภาษาอังกฤษตัวพิมพ์ใหญ่ 3 อักขระ"])},
  "312": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผลการค้นหา "])},
  "313": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เรียงตาม"])},
  "314": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชื่อ-นามสกุล"])},
  "315": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ข้อมูลเพิ่มเติม"])},
  "316": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กรุณาสืบค้นข้อมูลผู้ใช้"])},
  "317": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ค้นหาโดย รหัสนักศึกษา ชื่อ"])},
  "318": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ข้อมูลทั่วไป"])},
  "319": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["หลักสูตรใหม่"])},
  "320": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["หลักสูตรปรับปรุง"])},
  "321": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กรุณาเลือกต้นแบบ"])},
  "322": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เลือกต้นแบบ"])},
  "323": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ว่าง"])},
  "324": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(ฉบับร่าง)"])},
  "325": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ล่าสุด"])},
  "326": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เทมเพลตเพิ่มเติม"])},
  "327": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กลับ"])},
  "328": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ร่างหลักสูตร"])},
  "329": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แก้ไขหลักสูตร"])},
  "330": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่พบข้อมูล ร่างหลักสูตร"])},
  "331": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ค้นหาร่างหลักสูตร"])},
  "332": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เพิ่มร่างหลักสูตร"])},
  "333": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["พบข้อมูลหลักสูตร (ฉบับร่าง) ... รายการ"])},
  "334": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ประกาศนียบัตร"])},
  "335": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เปิดใช้งาน"])},
  "336": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["โครงสร้างหลักสูตร"])},
  "337": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รายวิชา"])},
  "338": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แผนการศึกษา"])},
  "339": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รหัสรายวิชา"])},
  "340": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รายวิชาเทียบเท่า"])},
  "341": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ข้อมูลเพิ่มเติม"])},
  "342": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชื่อหลักสูตร"])},
  "343": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วิทยาเขตนี้กำลังถูกใช้งาน"])},
  "344": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กรอกพิน (PIN)"])},
  "345": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กรอกพิน (PIN) เพื่อยืนยันการลบ"])},
  "346": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["อัปโหลดไอคอน"])},
  "347": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไอคอน"])},
  "348": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไอคอนของฉัน"])},
  "349": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ค้นหาไอคอน"])},
  "350": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่รองรับไฟล์ประเภทนี้ กรุณาอัปโหลดไฟล์ SVG"])},
  "351": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["ไฟล์ไอคอนนี้มีขนาดใหญ่เกินไป ขนาดสูงสุดต้องไม่เกิน ", _interpolate(_named("filesize")), " MB"])},
  "352": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ดำเนินการแล้ว"])},
  "353": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไอคอนจะถูกแสดงเมื่อเขตข้อมูลผู้ใช้ถูกกำหนดเป็นหัวเรื่องลำดับที่ 3 เท่านั้น"])},
  "354": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คุณต้องการเปลี่ยนสถานะของวิทยาเขตนี้ ใช่หรือไม่"])},
  "355": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กรอกพิน (PIN) เพื่อยืนยันการแก้ไข"])},
  "356": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["คุณกรอกพิน (PIN) ผิดเกิน ", _interpolate(_named("number")), " ครั้ง กรุณาติดต่อเจ้าหน้าที่ โทร. ", _interpolate(_named("phoneno"))])},
  "357": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["คุณกรอกพิน (PIN) ผิด ลองใหม่ได้อีก ", _interpolate(_named("number")), " ครั้ง"])},
  "358": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["คุณต้องการเปลี่ยนวิทยาเขต", _interpolate(_named("branch")), " เป็นวิทยาเขตหลัก ใช่หรือไม่"])},
  "359": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ที่อยู่วิทยาเขต (อังกฤษ)"])},
  "360": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["จังหวัด"])},
  "361": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["อำเภอ"])},
  "362": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ตำบล"])},
  "363": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รหัสไปรษณีย์"])},
  "364": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รหัสประเทศ"])},
  "365": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["โทรศัพท์ (ไทย)"])},
  "366": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["โทรศัพท์ (อังกฤษ)"])},
  "367": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แฟกซ์ (ไทย)"])},
  "368": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แฟกซ์ (อังกฤษ)"])},
  "369": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เลขที่ ชื่ออาคาร ถนน (ไทย)"])},
  "370": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เลขที่ ชื่ออาคาร ถนน (อังกฤษ)"])},
  "371": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ที่อยู่วิทยาเขต (ไทย)"])},
  "372": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ตัวอย่างการกรอกข้อมูลโทรศัพท์"])},
  "373": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ตัวอย่างการกรอกข้อมูลแฟกซ์"])},
  "374": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ขนาดรูปภาพสูงสุดต้องไม่เกิน 2 MB"])},
  "375": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ตัวอย่าง \\nแบบที่ 1: 075643210 หรือ 0-7564-3210 \\nแบบที่ 2: 075643210 ต่อ 1 ถึง 4 \\nแบบที่ 3: 075643210, 075484549"])},
  "376": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ตำบล อำเภอ จังหวัด รหัสไปรษณีย์ (ไทย)"])},
  "377": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ตำบล อำเภอ จังหวัด รหัสไปรษณีย์ (อังกฤษ)"])},
  "378": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แก้ไขวิทยาเขต"])},
  "379": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วิทยาเขตนี้เป็นวิทยาเขตหลัก ไม่สามารถเปลี่ยนสถานะได้"])},
  "380": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เลือกต้นแบบ"])},
  "381": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["หลักสูตร"])},
  "382": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["หมวดวิชาศึกษาทั่วไป"])},
  "383": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รูปแบบของหลักสูตร"])},
  "384": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สำหรับหลักสูตร"])},
  "385": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กรุณากรอกรหัสหลักสูตร 14 หลัก"])},
  "386": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ข้อมูลหมวดวิชาศึกษาทั่วไป"])},
  "387": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชื่อหมวดวิชา"])},
  "388": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แก้ไขตำแหน่ง"])},
  "389": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ที่อยู่หน่วยงาน (ไทย)"])},
  "390": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ที่อยู่หน่วยงาน (อังกฤษ)"])},
  "391": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["คุณกรอกชื่อผู้ใช้หรือพิน (pin) ผิดเกิน ", _interpolate(_named("LONGINFAILNUM")), " ครั้ง กรุณาติดต่อเจ้าหน้าที่โทร ", _interpolate(_named("LockContact"))])},
  "392": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["คุณกรอกชื่อผู้ใช้หรือพิน (pin) ผิด ลองใหม่ได้อีก ", _interpolate(_named("LONGINFAILNUM")), " ครั้ง"])},
  "393": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แก้ไขหน่วยงาน"])},
  "394": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แก้ไขประเภทผู้ใช้"])},
  "395": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["หน่วยงานย่อยจะถูกเปลี่ยนสถานะด้วย"])},
  "396": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["หน่วยงานย่อยจะถูกลบด้วย"])},
  "397": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["คุณต้องการเปลี่ยนสถานะของ", _interpolate(_named("fieldname")), "นี้ ใช่หรือไม่"])},
  "398": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แก้ไขระดับปริญญา"])},
  "399": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แก้ไขคำนำหน้าชื่อ"])},
  "400": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แก้ไขหน่วยงานวิชาการ"])},
  "401": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การจัดการผู้ใช้"])},
  "402": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["หน่วยงานนี้กำลังใช้งาน ไม่สามารถเปลี่ยนสถานะได้ กรุณาแก้ไขสถานะหน่วยงานย่อยทั้งหมดเป็นปิดก่อนทำการแก้ไข"])},
  "403": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["หน่วยงานหลักจะถูกเปลี่ยนสถานะด้วย"])},
  "404": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กรุณากรอกชื่อวิทยาเขต ก่อนทำการเปลี่ยนเป็นวิทยาเขตหลัก"])},
  "405": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([".jpeg, .png"])},
  "406": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แก้ไขข้อมูลผู้ใช้"])},
  "407": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คุณต้องการเปลี่ยนสถานะของหน่วยงานนี้ ใช่หรือไม่"])},
  "408": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แก้ไขพารามิเตอร์"])},
  "409": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คุณต้องการเปลี่ยนสถานะของระดับปริญญานี้ ใช่หรือไม่"])},
  "410": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คุณต้องการเปลี่ยนสถานะของตำแหน่งนี้ ใช่หรือไม่"])},
  "411": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การเพิ่มหน่วยงานวิชาการย่อย ทำได้เมื่อหน่วยงานวิชาการหลัก มีสถานะเป็นเปิดหรือระหว่างดำเนินการ"])},
  "412": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กรุณาเลือกสถานะหน่วยงานวิชาการหลัก"])},
  "413": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ปี"])},
  "414": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เดือน"])},
  "415": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วัน"])},
  "416": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["หน่วยงานหลักจะถูกเปลี่ยนสถานะด้วย"])},
  "417": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["หน่วยงาน"])},
  "418": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("fieldname")), "นี้กำลังถูกใช้งาน"])},
  "419": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["กรุณากรอกข้อมูล", _interpolate(_named("fieldname"))])},
  "420": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["หน่วยงานวิชาการ"])},
  "421": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คุณต้องการเปลี่ยนสถานะของหน่วยงานวิชาการนี้ ใช่หรือไม่"])},
  "422": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ตัวอย่างการกรอกข้อมูลระยะเวลาการดำรงตำแหน่ง"])},
  "423": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ตัวอย่าง \\n แบบที่ 1: 1 ปี 10 เดือน 24 วัน \\n แบบที่ 2: 1 ปี 10 เดือน \\n แบบที่ 3: 1 ปี"])},
  "424": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ระยะเวลาการดำรงตำแหน่งอย่างน้อย 1 วัน"])},
  "425": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ระยะเวลาการดำรงตำแหน่งสูงสุด 60 ปี"])},
  "426": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["แก้ไข", _interpolate(_named("fieldname"))])},
  "427": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คุณใส่ตัวอักษรมากกว่าที่ระบบกำหนด"])},
  "428": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ข้อมูลนี้เพิ่งถูกลบไป ไม่สามารถลบได้"])},
  "429": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ข้อมูลนี้เพิ่งถูกลบไป ไม่สามารถเพิ่มได้"])},
  "430": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ข้อมูลนี้มีสถานะเปิด ไม่สามารถลบได้"])},
  "431": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ข้อมูลหน่วยงานวิชาการย่อย"])},
  "432": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ข้อมูลหน่วยงานย่อย"])},
  "433": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" รายการ สำหรับ "])},
  "434": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ค้นหาข้อมูลผู้ใช้"])},
  "435": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["มากไปน้อย"])},
  "436": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["น้อยไปมาก"])},
  "437": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไอดีผู้ใช้"])},
  "438": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชื่อเต็ม"])},
  "439": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ก-ฮ, A-Z"])},
  "440": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Z-A,  ฮ-ก"])},
  "441": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การเปลี่ยนประเภทข้อมูลทำให้ข้อมูลสูญหาย คุณยืนยันที่จะเปลี่ยนประเภทข้อมูล ใช่หรือไม่"])},
  "442": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["กรุณากรอกปี พ.ศ ระหว่าง ", _interpolate(_named("minyear")), " - ", _interpolate(_named("maxyear"))])},
  "443": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กรุณากรอกจำนวนเงิน"])},
  "444": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ข้อมูล (ไทย)"])},
  "445": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ข้อมูล (อังกฤษ)"])},
  "446": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ค้นหากลุ่มข้อมูล"])},
  "447": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["ค้นหา", _interpolate(_named("fieldname"))])},
  "448": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กลุ่มผู้ใช้"])},
  "449": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รอผลการค้นหา"])},
  "450": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["นักศึกษา"])},
  "451": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เจ้าหน้าที่"])},
  "452": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["บุคคลภายนอก"])},
  "453": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เมื่อเพิ่มข้อมูลสำเร็จ จะไม่สามารถแก้ไขกลุ่มผู้ใช้งานได้ ยืนยันการเพิ่มข้อมูล ใช่หรือไม่"])},
  "454": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่พบข้อมูลผู้ใช้"])},
  "455": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การจัดการการเปิดรายวิชา"])},
  "456": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การเปิดรายวิชา"])},
  "457": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["หมดเวลาในการเปิดรายวิชา"])},
  "458": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชั่วโมง"])},
  "459": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["นาที"])},
  "460": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การจัดการศึกษา"])},
  "461": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไตรภาค"])},
  "462": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ทวิภาค"])},
  "463": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ภาค/ปีการศึกษา"])},
  "464": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วิชาแกนหรือเอกบังคับ"])},
  "465": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รายวิชาเอกบังคับ"])},
  "466": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รายวิชาเอกเลือก"])},
  "467": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รายวิชาเลือกเสรี"])},
  "468": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รายวิชาโท"])},
  "469": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รายวิชาที่ดำเนินการแล้ว"])},
  "470": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รายวิชา"])},
  "471": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชื่อรายวิชา (อังกฤษ)"])},
  "472": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กลุ่มวิชา"])},
  "473": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["จำนวนผู้เรียน (ที่นั่ง)"])},
  "474": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["จำนวนที่รับได้ (ที่นั่ง)"])},
  "475": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รายวิชาที่ต่อจากวิชานี้"])},
  "476": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผู้เรียนตามแผนการศึกษา"])},
  "477": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ที่่นั่ง"])},
  "478": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รอดำเนินการ"])},
  "479": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เหตุผลที่ไม่อนุมัติ"])},
  "480": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เปิดครั้งถัดไป"])},
  "481": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผู้เรียนที่ตกค้าง"])},
  "482": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เหตุผลที่ตกค้าง"])},
  "483": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผู้ยื่นคำร้อง"])},
  "484": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผลการเรียน F"])},
  "485": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่ลงทะเบียนตามแผนการศึกษา"])},
  "486": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผลการเรียน W"])},
  "487": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผลการเรียน U"])},
  "488": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ค้นหารายวิชาแกนหรือเอกบังคับ"])},
  "489": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เปิดรายวิชาเพิ่มเติม"])},
  "490": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คำร้องโดยนักศึกษา"])},
  "491": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เหตุผลการยื่นคำร้อง"])},
  "492": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วัน เวลา ยื่นคำร้อง"])},
  "493": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รายวิชาเอกเลือก"])},
  "494": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รายวิชาที่มีนักศึกษาของหลักสูตรตกค้าง"])},
  "495": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รายวิชาที่มีการขอให้เปิด"])},
  "496": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รายวิชาที่เปิดโดยหลักสูตรอื่น"])},
  "497": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผลการอนุมัติ"])},
  "498": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["อนุมัติ"])},
  "499": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่อนุมัติ"])},
  "500": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผู้ยื่นคำร้อง"])},
  "501": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชั้นปี"])},
  "502": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เหลือเวลาในการเปิดรายวิชา"])},
  "503": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การเปิดรายวิชาที่นอกเหนือจากช่วงเวลาที่กำหนดโปรดติดต่อ ศบศ."])},
  "504": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผู้เรียน"])},
  "505": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คำร้องโดยอาจารย์"])},
  "506": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วัน"])},
  "507": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วิชาแกน"])},
  "508": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วิชาเอกบังคับ"])},
  "509": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["จำนวนผู้เรียน: ", _interpolate(_named("fieldname")), " ที่นั่ง"])},
  "510": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["จำนวนที่รับได้: ", _interpolate(_named("fieldname")), " ที่นั่ง"])},
  "511": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชุดเขตข้อมูล"])},
  "512": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รอข้อมูล"])},
  "513": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชื่อรายวิชา"])},
  "514": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วิชาแกนหรือเอกบังคับ"])},
  "515": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เลือกหัวเรื่อง"])},
  "516": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["หัวเรื่อง 4"])},
  "517": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กลุ่มผู้ใช้"])},
  "518": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชื่อกลุ่มผู้ใช้ (อังกฤษ)"])},
  "519": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชื่อกลุ่มผู้ใช้"])},
  "520": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["จำนวนผู้ใช้"])},
  "521": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สถานะ"])},
  "522": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เรียกดู"])},
  "523": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ค้นหากลุ่มผู้ใช้"])},
  "524": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คุณต้องการเปลี่ยนสถานะกลุ่มผู้ใช้นี้ ใช่หรือไม่"])},
  "525": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กรุณากรอกข้อมูลกลุ่มผู้ใช้"])},
  "526": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กรุณาระบุสิทธิ์การเข้าถึงเขตข้อมูลผู้ใช้"])},
  "527": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เขตข้อมูลที่ระบุสิทธิ์การเข้าถึง"])},
  "528": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สมาชิกในกลุ่ม"])},
  "529": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สิทธิ์การเข้าถึงเขตข้อมูลของกลุ่มผู้ใช้"])},
  "530": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชื่อวิทยาเขต (อังกฤษ)"])},
  "531": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชื่อย่อวิทยาเขต (อังกฤษ)"])},
  "532": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ที่อยู่วิทยาเขต (อังกฤษ)"])},
  "533": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชื่อวิทยาเขต"])},
  "534": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชื่อย่อวิทยาเขต"])},
  "535": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ที่อยู่วิทยาเขต"])},
  "536": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชื่อหน่วยงาน (อังกฤษ)"])},
  "537": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชื่อย่อหน่วยงาน (อังกฤษ)"])},
  "538": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ที่อยู่หน่วยงาน (อังกฤษ)"])},
  "539": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชื่อหน่วยงาน"])},
  "540": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชื่อย่อหน่วยงาน"])},
  "541": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ที่อยู่หน่วยงาน"])},
  "542": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["โทรศัพท์"])},
  "543": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["โทรศัพท์ (อังกฤษ)"])},
  "544": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แฟกซ์"])},
  "545": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แฟกซ์ (อังกฤษ)"])},
  "546": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชื่อตำแหน่ง (อังกฤษ)"])},
  "547": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชื่อตำแหน่ง"])},
  "548": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชื่อหน่วยงานวิชาการ(อังกฤษ)"])},
  "549": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชื่อย่อหน่วยงานวิชาการ (อังกฤษ)"])},
  "550": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชื่อหน่วยงานวิชาการ"])},
  "551": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชื่อย่อหน่วยงานวิชาการ"])},
  "552": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชื่อระดับปริญญา (อังกฤษ)"])},
  "553": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชื่อระดับปริญญา"])},
  "554": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชื่อประเภทผู้ใช้ (อังกฤษ)"])},
  "555": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชื่อประเภทผู้ใช้"])},
  "556": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คำนำหน้าชื่อ (อังกฤษ)"])},
  "557": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คำนำหน้าชื่อ"])},
  "558": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชื่อย่อคำนำหน้าชื่อ (อังกฤษ)"])},
  "559": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชื่อย่อคำนำหน้าชื่อ"])},
  "560": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชื่อเขตข้อมูล (อังกฤษ)"])},
  "561": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชื่อเขตข้อมูล"])},
  "562": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เพิ่มกลุ่มผู้ใช้"])},
  "563": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แก้ไขกลุ่มผู้ใช้"])},
  "564": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เพิ่มสิทธิ์การเข้าถึงเขตข้อมูลของกลุ่มผู้ใช้"])},
  "565": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แก้ไขสิทธิ์การเข้าถึงเขตข้อมูลของกลุ่มผู้ใช้"])},
  "566": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชื่อเทมเพลต (อังกฤษ)"])},
  "567": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ปิดรายวิชา"])},
  "568": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รายวิชาต่อเนื่อง"])},
  "569": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["จำนวนผู้เรียน"])},
  "570": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ที่นั่ง"])},
  "571": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รายละเอียดผู้เรียน"])},
  "572": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กำหนดผู้เรียนเพิ่มเติม"])},
  "573": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เพิ่มผู้เรียน"])},
  "574": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กำหนดรายวิชาเทียบเท่าที่ต้องการให้เรียนร่วม"])},
  "575": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กำหนดรายวิชาอื่นๆ ที่ต้องการให้เรียนร่วม"])},
  "576": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กำหนดผู้ไม่มีสิทธิ์ลงทะเบียน"])},
  "577": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผู้ไม่มีสิทธิ์ลงทะเบียน"])},
  "578": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ประวัติการเปิดรายวิชา"])},
  "579": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["จำนวนผู้มีสิทธิ์ลงทะเบียน"])},
  "580": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กำหนดการสอบ"])},
  "581": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กลางภาค"])},
  "582": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่จัดสอบ"])},
  "583": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ปลายภาค"])},
  "584": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ยังไม่กำหนด"])},
  "585": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รายละเอียดการสอบ"])},
  "586": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กำหนดชั่วโมงการสอน"])},
  "587": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การสอนบรรยาย"])},
  "588": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การสอนปฏิบัติ"])},
  "589": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผู้มีสิทธิ์ลงทะเบียน"])},
  "590": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เปิดรายวิชา"])},
  "591": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รอกำหนดชั่วโมงการสอน"])},
  "592": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผู้เรียนที่ตกค้าง"])},
  "593": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผู้เรียนที่ยื่นคำร้อง"])},
  "594": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผู้เรียนเพิ่มเติม"])},
  "595": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผู้เรียนจากรายวิชาเทียบเท่า"])},
  "596": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผู้เรียนจากรายวิชาอื่น ๆ ที่ต้องการให้เรียนร่วม"])},
  "597": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ยกเลิกคำร้อง"])},
  "598": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผู้สอน"])},
  "599": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผู้อนุมัติ"])},
  "600": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เพิ่มคำร้อง"])},
  "601": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วันที่ยื่นคำร้อง"])},
  "602": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["จำนวนผู้ไม่มีสิทธิ์ลงทะเบียน"])},
  "603": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เรียนร่วม"])},
  "604": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เทียบเท่า"])},
  "605": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["มกราคม"])},
  "606": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กุมภาพันธ์"])},
  "607": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["มีนาคม"])},
  "608": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เมษายน"])},
  "609": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["พฤษภาคม"])},
  "610": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["มิถุนายน"])},
  "611": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กรกฎาคม"])},
  "612": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สิงหาคม"])},
  "613": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กันยายน"])},
  "614": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ตุลาคม"])},
  "615": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["พฤศจิกายน"])},
  "616": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ธันวาคม"])},
  "617": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ค้นหาคำร้อง"])},
  "618": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เหตุผลที่ยื่นคำร้อง"])},
  "619": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คุณต้องการยกเลิกคำร้องรายวิชานี้ ใช่หรือไม่"])},
  "620": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เหตุผลการยกเลิกคำร้อง"])},
  "621": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รออนุมัติ"])},
  "622": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ยกเลิกคำร้องสำเร็จ"])},
  "623": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เพิ่มคำร้องสำเร็จ"])},
  "624": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การยื่นคำร้อง"])},
  "625": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การจัดการคำร้อง"])},
  "626": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กรุณากรอกข้อมูลคำร้อง"])},
  "627": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กำหนดรายวิชาที่ยื่นคำร้อง"])},
  "628": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ค้นหารหัสรายวิชาที่ยื่นคำร้อง"])},
  "629": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เพื่อแก้ไขเกรดให้สูงขึ้น"])},
  "630": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วิชาบังคับก่อน"])},
  "631": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เพื่อใช้เป็นวิชาเลือกเสรี"])},
  "632": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เพิ่มเหตุผล"])},
  "633": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เหตุผล"])},
  "634": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ส่งคำร้อง"])},
  "635": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กำหนดผู้เรียนที่ต้องการยื่นคำร้อง"])},
  "636": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สำนักวิชา"])},
  "637": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รายการผู้เรียนที่ต้องการยื่นคำร้อง"])},
  "638": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รหัสนักศึกษา"])},
  "639": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ภาค/ปีการศึกษาที่เปิดรายวิชา"])},
  "640": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["อาจารย์"])},
  "641": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผู้อนุมัติ (วันที่/เวลา)"])},
  "642": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["จำนวนผู้เรียนที่ต้องการยื่นคำร้อง: 10 คน"])},
  "643": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ประวัติคำร้อง"])},
  "644": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ตัวอย่าง (ไทย)"])},
  "645": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ตัวอย่าง (อังกฤษ)"])},
  "646": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วันเวลาซ้ำ กรุณาแก้ไข"])},
  "647": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เวลาที่เริ่มเผยแพร่ไม่ถูกต้อง"])},
  "648": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เพิ่มกลุ่มผู้ใช้"])},
  "649": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รายบุคคล"])},
  "650": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กำหนดสมาชิก"])},
  "651": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เพิ่มสมาชิก"])},
  "652": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เข้าสู่ระบบ"])},
  "653": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ติดต่อศูนย์เทคโนโลยีดิจิทัล"])},
  "654": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ลืมรหัสผ่าน"])},
  "655": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["หน้าแรก"])},
  "656": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["หมวดหมู่ที่ชอบ"])},
  "657": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["บันทึกแล้ว"])},
  "658": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไทย"])},
  "659": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่พบข้อความแจ้งเตือน"])},
  "660": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ออกจากระบบ"])},
  "661": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ข้อความแจ้งเตือน"])},
  "662": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["จัดการข้อความแจ้งเตือน"])},
  "663": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ค้นหาข้อความแจ้งเตือน"])},
  "664": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สร้างข้อความ"])},
  "665": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["หัวข้อ"])},
  "666": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ช่วงเวลาที่เผยแพร่"])},
  "667": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผู้รับ"])},
  "668": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["หมวดหมู่"])},
  "669": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รอเผยแพร่"])},
  "670": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กำลังเผยแพร่"])},
  "671": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สิ้นสุดเวลาเผยแพร่"])},
  "672": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผู้เผยแพร่"])},
  "673": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["หัวข้อ (อังกฤษ)"])},
  "674": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ระดับความสำคัญ"])},
  "675": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สูง"])},
  "676": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ปกติ"])},
  "677": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การแจ้งเตือน"])},
  "678": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ทุกคน"])},
  "679": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กำหนดรายละเอียดผู้รับ"])},
  "680": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เลือกจากรายวิชาที่สอน"])},
  "681": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กำหนดแบบกลุ่ม"])},
  "682": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กำหนดรายบุคคล"])},
  "683": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แบบกลุ่ม"])},
  "684": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แบบบุคคล"])},
  "685": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["หัวข้อ (ไทย)"])},
  "686": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รายละเอียด"])},
  "687": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["เอกสารแนบ (สามารถแนบไฟล์ได้สูงสุด  ", _interpolate(_named("numberoffiles")), "  ไฟล์)"])},
  "688": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เวลาเริ่มเผยแพร่"])},
  "689": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เวลาสิ้นสุดเผยแพร่"])},
  "690": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เวลาแจ้งเตือนซ้ำ"])},
  "691": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["ส่งข้อความซ้ำ (ได้สูงสุด ", _interpolate(_named("numberofrepeats")), " ครั้ง)"])},
  "692": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วัน/เวลา แจ้งเตือนซ้ำ"])},
  "693": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กรุณาแนบไฟล์ .pdf เท่านั้น "])},
  "694": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["ไฟล์นี้มีขนาดใหญ่เกินไป ขนาดไฟล์สูงสุดต้องไม่เกิน ", _interpolate(_named("filesize")), " MB"])},
  "695": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไฟล์นี้มีอยู่แล้ว คุณต้องการแทนที่ ใช่หรือไม่"])},
  "696": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ข้อความแจ้งเตือนอัตโนมัติ"])},
  "697": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["โมดูล"])},
  "698": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["โมดูลย่อย"])},
  "699": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คำอธิบาย"])},
  "700": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กรุณากรอกข้อความแจ้งเตือน"])},
  "701": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["จัดการหมวดหมู่ข้อความ"])},
  "702": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ค้นหาหมวดหมู่ข้อความ"])},
  "703": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["ภาพหมวดหมู่ (ขนาดไม่เกิน ", _interpolate(_named("filesize")), "  MB)"])},
  "704": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สร้างหมวดหมู่"])},
  "705": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชื่อหมวดหมู่ (อังกฤษ)"])},
  "706": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชื่อหมวดหมู่ (ไทย)"])},
  "707": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รายละเอียดข้อความ (อังกฤษ)"])},
  "708": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รายละเอียดข้อความ (ไทย)"])},
  "709": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ต่อ 12345"])},
  "710": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ครั้งที่"])},
  "711": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คัดลอกข้อความจากหัวข้อ"])},
  "712": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ค้นหารหัสรายวิชา"])},
  "713": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เหตุผลการปิดรายวิชา"])},
  "714": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รหัสผู้ใช้"])},
  "715": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผู้มีสิทธิ์"])},
  "716": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["บันทึกคำร้อง"])},
  "717": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เส้นทางเอกสาร"])},
  "718": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แจ้งเตือนสำคัญ"])},
  "719": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["เพิ่ม", _interpolate(_named("fieldname"))])},
  "720": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["แก้ไข", _interpolate(_named("fieldname"))])},
  "721": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กรุณาเพิ่มสมาชิก"])},
  "722": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ค้นหาสมาชิก"])},
  "723": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เพิ่มสมาชิก"])},
  "724": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ค้นหาผู้ใช้"])},
  "725": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ยืนยันการออกจากระบบ?"])},
  "726": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คำร้องขอลาระหว่างเรียน"])},
  "727": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คำร้องขอลาระหว่างสอบ"])},
  "728": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คำร้องขอลาออก"])},
  "729": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คำร้องขอเพิ่มรายวิชา"])},
  "730": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คำร้องขอลดรายวิชา"])},
  "731": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คำร้องขอถอนรายวิชา"])},
  "732": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คำร้องขอรักษาสภาพ"])},
  "733": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คำร้องขอคืนสภาพนักศึกษา"])},
  "734": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คำร้องขอลงทะเบียนมากกว่าเกณฑ์"])},
  "735": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คำร้องขอลงทะเบียนต่ำกว่าเกณฑ์        "])},
  "736": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["อาจารย์ที่ปรึกษา"])},
  "737": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คณบดี"])},
  "738": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การเปลี่ยนแปลงช่วงเวลาที่เผยแพร่ ทำให้วันเวลาแจ้งเตือนซ้ำสูญหาย \\nคุณต้องการเปลี่ยนช่วงเวลาเผยแพร่ ใช่หรือไม่"])},
  "739": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["ท่านมีรายการคำร้องขอลาระหว่างเรียน รออนุมัติ จำนวน ", _interpolate(_named("requestsnumber")), " รายการ โปรดดำเนินการ ", _interpolate(_named("Link"))])},
  "740": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["โปรดตรวจสอบสถานะคำร้องขอหลัง 08.30"])},
  "741": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["โปรดตรวจสอบสถานะคำร้องขอในวันทำการถัดไป"])},
  "742": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["คำร้องขอลาระหว่างเรียน รายวิชา ", _interpolate(_named("Subject_codes")), " ", _interpolate(_named("Subjects_name")), " ", _interpolate(_named("Date_time")), " ผู้สอนอนุมัติ"])},
  "743": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["คำร้องขอลาระหว่างเรียน รายวิชา ", _interpolate(_named("Subject_codes")), " ", _interpolate(_named("Subjects_name")), " ", _interpolate(_named("Date_time")), " รอผู้สอนอนุมัติ"])},
  "744": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["คำร้องขอลาระหว่างเรียน รายวิชา ", _interpolate(_named("Subject_codes")), " ", _interpolate(_named("Subjects_name")), " ", _interpolate(_named("Date_time")), " ผู้สอนไม่อนุมัติ"])},
  "745": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["กรุณาเพิ่มจำนวนผู้เรียน สำหรับ", _interpolate(_named("Subject_codes")), " ", _interpolate(_named("Subjects_name"))])},
  "746": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สมาชิก"])},
  "747": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชื่อประเภทผู้ใช้"])},
  "748": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชื่อหน่วยงานวิชาการ"])},
  "749": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชื่อหน่วยงาน"])},
  "750": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["คำร้องขอเพิ่มรายวิชา ", _interpolate(_named("Subject_codes")), " ", _interpolate(_named("Subjects_name")), "  รอผู้สอนอนุมัติ"])},
  "751": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["คำร้องขอเพิ่มรายวิชา ", _interpolate(_named("Subject_codes")), " ", _interpolate(_named("Subjects_name")), " ดำเนินการเสร็จสิ้นแล้ว"])},
  "752": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["คำร้องขอเพิ่มรายวิชา ", _interpolate(_named("Subject_codes")), " ", _interpolate(_named("Subjects_name")), " ผู้สอนไม่อนุมัติ"])},
  "753": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["คำร้องขอเพิ่มรายวิชา ", _interpolate(_named("Subject_codes")), " ", _interpolate(_named("Subjects_name")), " อาจารย์ที่ปรึกษาไม่อนุมัติ"])},
  "754": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["คำร้องขอเพิ่มรายวิชา ", _interpolate(_named("Subject_codes")), " ", _interpolate(_named("Subjects_name")), " คณบดีไม่อนุมัติ"])},
  "755": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["คำร้องขอถอนรายวิชา ", _interpolate(_named("Subject_codes")), " ", _interpolate(_named("Subjects_name")), " รอผู้สอนอนุมัติ"])},
  "756": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["คำร้องขอถอนรายวิชา ", _interpolate(_named("Subject_codes")), " ", _interpolate(_named("Subjects_name")), " ดำเนินการเสร็จสิ้นแล้ว"])},
  "757": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["คำร้องขอถอนรายวิชา ", _interpolate(_named("Subject_codes")), " ", _interpolate(_named("Subjects_name")), " เจ้าหน้าที่กำลังดำเนินการ"])},
  "758": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["คำร้องขอลงทะเบียนมากกว่า-น้อยกว่าเกณฑ์  ", _interpolate(_named("Term")), "/", _interpolate(_named("academicyear")), " รอคณบดีอนุมัติ"])},
  "759": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["คำร้องขอลงทะเบียนมากกว่า-น้อยกว่าเกณฑ์  ", _interpolate(_named("Term")), "/", _interpolate(_named("academicyear")), " ดำเนินการเสร็จสิ้นแล้ว"])},
  "760": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["เครือข่าย Wi-Fi ", _interpolate(_named("wifiname")), " ไม่ได้เชื่อมต่อกับอินเทอร์เน็ต"])},
  "761": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คุณต้องการเชื่อมต่อผ่านเครือข่ายโทรศัพท์มือถือ ใช่หรือไม่?"])},
  "762": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ใช้การเชื่อมต่อจากเครือข่ายโทรศัพท์มือถือ"])},
  "763": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เชื่อมต่อผ่าน Wi-Fi"])},
  "764": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เลือกวัน/เวลาไม่ถูกต้อง"])},
  "765": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เรายินดีรับฟังข้อเสนอแนะจากคุณ"])},
  "766": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แน่นอน ด้วยความยินดี"])},
  "767": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่ล่ะ ขอบคุณ"])},
  "768": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ขอพบนักศึกษา"])},
  "769": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การเรียนการสอน"])},
  "770": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การสอบ"])},
  "771": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กิจกรรม"])},
  "772": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ภาระค่าใช้จ่าย/ทุนการศึกษา"])},
  "773": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่พบหมวดหมู่ที่ชอบ"])},
  "774": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ภาค"])},
  "775": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ปีการศึกษา"])},
  "776": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คุณต้องการลบกลุ่มผู้ใช้นี้ ใช่หรือไม่"])},
  "777": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เครือข่ายโทรศัพท์มือถือไม่ได้เชื่อมต่ออินเทอร์เน็ต"])},
  "778": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คุณต้องการเชื่อมต่อ Wi-Fi ใช่หรือไม่"])},
  "779": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เชื่อมต่อผ่านเครือข่ายโทรศัพท์มือถือ"])},
  "780": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เชื่อมต่อผ่าน Wi-Fi"])},
  "781": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ปิดโหมดเครื่องบินหรือใช้ Wi-Fi เพื่อเข้าถึงข้อมูล"])},
  "782": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คุณต้องการปิดโหมดเครื่องบิน ใช่หรือไม่"])},
  "783": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่มีการเชื่อมต่ออินเทอร์เน็ต"])},
  "784": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คุณต้องการเปิดเครือข่ายโทรศัทพ์มือถือหรือ Wi-Fi ใช่หรือไม่"])},
  "785": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["ใช้ข้อมูลการสอบล่าสุด ", _interpolate(_named("Term")), "/", _interpolate(_named("academicyear"))])},
  "786": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["มีสอบ"])},
  "787": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่มีสอบ"])},
  "788": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["จัดสอบโดยผู้สอน"])},
  "789": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["จัดสอบโดย ศบศ."])},
  "790": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["จำนวนชั่วโมง"])},
  "791": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ระเบียบการสอบ"])},
  "792": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เฟซบุ๊ก เมสเซนเจอร์"])},
  "793": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["ช่วง 16.00 เมื่อวานถึง 15.59 วันนี้ นักศึกษาในที่ปรึกษาของท่านส่งคำร้องขอลาระหว่างเรียน จำนวน ", _interpolate(_named("totalStudentRequestNumber")), " คน รายละเอียดเพิ่มเติม"])},
  "794": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["ท่านมีรายการคำร้องขอเพิ่ม/ลด/เปลี่ยนกลุ่ม รออนุมัติ จำนวน ", _interpolate(_named("requestsnumber")), " รายการ โปรดดำเนินการ ", _interpolate(_named("Link"))])},
  "795": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["คำร้องขอเพิ่มรายวิชา ", _interpolate(_named("Subject_codes")), " ", _interpolate(_named("Subjects_name")), " รออาจารย์ที่ปรึกษาอนุมัติ"])},
  "796": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["คำร้องขอลดรายวิชา ", _interpolate(_named("Subject_codes")), " ", _interpolate(_named("Subjects_name")), " รออาจารย์ที่ปรึกษาอนุมัติ"])},
  "797": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["คำร้องขอเปลี่ยนกลุ่มรายวิชา ", _interpolate(_named("Subject_codes")), " ", _interpolate(_named("Subjects_name")), " รออาจารย์ที่ปรึกษาอนุมัติ"])},
  "798": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["คำร้องขอเพิ่มรายวิชา ", _interpolate(_named("Subject_codes")), " ", _interpolate(_named("Subjects_name")), " อาจารย์ที่ปรึกษาไม่อนุมัติ"])},
  "799": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["คำร้องขอลดรายวิชา ", _interpolate(_named("Subject_codes")), " ", _interpolate(_named("Subjects_name")), " อาจารย์ที่ปรึกษาไม่อนุมัติ"])},
  "800": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["คำร้องขอเปลี่ยนกลุ่มรายวิชา ", _interpolate(_named("Subject_codes")), " ", _interpolate(_named("Subjects_name")), " อาจารย์ที่ปรึกษาไม่อนุมัติ"])},
  "801": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["คำร้องขอเพิ่มรายวิชา ", _interpolate(_named("Subject_codes")), " ", _interpolate(_named("Subjects_name")), " รอผู้สอนอนุมัติ"])},
  "802": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["คำร้องขอลดรายวิชา ", _interpolate(_named("Subject_codes")), " ", _interpolate(_named("Subjects_name")), " รอผู้สอนอนุมัติ"])},
  "803": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["คำร้องขอเปลี่ยนกลุ่มรายวิชา ", _interpolate(_named("Subject_codes")), " ", _interpolate(_named("Subjects_name")), " รอผู้สอนอนุมัติ"])},
  "804": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["คำร้องขอเพิ่มรายวิชา ", _interpolate(_named("Subject_codes")), " ", _interpolate(_named("Subjects_name")), " ผู้สอนอนุมัติแล้ว กรุณาเข้าระบบศูนย์บริการการศึกษาเพื่อยืนยันการลงทะเบียนอีกครั้ง"])},
  "805": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["คำร้องขอลดรายวิชา ", _interpolate(_named("Subject_codes")), " ", _interpolate(_named("Subjects_name")), " ผู้สอนอนุมัติแล้ว กรุณาเข้าระบบศูนย์บริการการศึกษาเพื่อยืนยันการลงทะเบียนอีกครั้ง"])},
  "806": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["คำร้องขอเปลี่ยนกลุ่มรายวิชา ", _interpolate(_named("Subject_codes")), " ", _interpolate(_named("Subjects_name")), " ผู้สอนอนุมัติแล้ว กรุณาเข้าระบบศูนย์บริการการศึกษาเพื่อยืนยันการลงทะเบียนอีกครั้ง"])},
  "807": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["คำร้องขอเพิ่มรายวิชา ", _interpolate(_named("Subject_codes")), " ", _interpolate(_named("Subjects_name")), " ผู้ไม่สอนอนุมัติ"])},
  "808": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["คำร้องขอลดรายวิชา ", _interpolate(_named("Subject_codes")), " ", _interpolate(_named("Subjects_name")), " ผู้สอนไม่อนุมัติ"])},
  "809": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["คำร้องขอเปลี่ยนกลุ่มรายวิชา ", _interpolate(_named("Subject_codes")), " ", _interpolate(_named("Subjects_name")), " ผู้สอนไม่อนุมัติ"])},
  "810": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["ท่านมีรายการคำร้องขอลงทะเบียนน้อยกว่าหรือมากกว่าเกณฑ์  ", _interpolate(_named("Term")), "/", _interpolate(_named("academicyear")), " รออนุมัติ จำนวน ", _interpolate(_named("requestsnumber")), " รายการ"])},
  "811": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["คำร้องขอลงทะเบียนมากกว่าเกณฑ์  ", _interpolate(_named("Term")), "/", _interpolate(_named("academicyear")), " รออาจารย์ที่ปรึกษาอนุมัติ"])},
  "812": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["คำร้องขอลงทะเบียนน้อยกว่าเกณฑ์  ", _interpolate(_named("Term")), "/", _interpolate(_named("academicyear")), " รออาจารย์ที่ปรึกษาอนุมัติ"])},
  "813": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["คำร้องขอลงทะเบียนน้อยกว่าเกณฑ์  ", _interpolate(_named("Term")), "/", _interpolate(_named("academicyear")), " รอคณบดีอนุมัติ"])},
  "814": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["คำร้องขอลงทะเบียนมากกว่าเกณฑ์  ", _interpolate(_named("Term")), "/", _interpolate(_named("academicyear")), " รอคณบดีอนุมัติ"])},
  "815": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["คำร้องขอลงทะเบียนน้อยกว่าเกณฑ์  ", _interpolate(_named("Term")), "/", _interpolate(_named("academicyear")), " อาจารย์ที่ปรึกษาไม่อนุมัติ"])},
  "816": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["คำร้องขอลงทะเบียนมากกว่าเกณฑ์  ", _interpolate(_named("Term")), "/", _interpolate(_named("academicyear")), " อาจารย์ที่ปรึกษาไม่อนุมัติ"])},
  "817": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["คำร้องขอลงทะเบียนน้อยกว่าเกณฑ์  ", _interpolate(_named("Term")), "/", _interpolate(_named("academicyear")), " คณบดีอนุมัติแล้ว"])},
  "818": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["คำร้องขอลงทะเบียนมากกว่าเกณฑ์  ", _interpolate(_named("Term")), "/", _interpolate(_named("academicyear")), " คณบดีอนุมัติแล้ว"])},
  "819": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["คำร้องขอลงทะเบียนน้อยกว่าเกณฑ์  ", _interpolate(_named("Term")), "/", _interpolate(_named("academicyear")), "  คณบดีไม่อนุมัติ"])},
  "820": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["คำร้องขอลงทะเบียนมากกว่าเกณฑ์  ", _interpolate(_named("Term")), "/", _interpolate(_named("academicyear")), " คณบดีไม่อนุมัติ"])},
  "821": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["ท่านมีรายการคำร้องขอถอนรายวิชา (กรณีติด W) รออนุมัติ จำนวน ", _interpolate(_named("requestsnumber")), " รายการ"])},
  "822": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["คำร้องขอถอนรายวิชา (กรณีติด W) ", _interpolate(_named("Subject_codes")), " ", _interpolate(_named("Subjects_name")), "  รออาจารย์ที่ปรึกษาอนุมัติ"])},
  "823": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["พ้นสภาพนักศึกษา (ลาออก)"])},
  "824": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["คำร้องขอถอนรายวิชา (กรณีติด W) ", _interpolate(_named("Subject_codes")), " ", _interpolate(_named("Subjects_name")), "  เจ้าหน้าที่กำลังดำเนินการ"])},
  "825": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["คำร้องขอถอนรายวิชา (กรณีติด W) ", _interpolate(_named("Subject_codes")), " ", _interpolate(_named("Subjects_name")), " ผู้สอนไม่อนุมัติ"])},
  "826": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["คำร้องขอถอนรายวิชา (กรณีติด W) ", _interpolate(_named("Subject_codes")), " ", _interpolate(_named("Subjects_name")), " ดำเนินการเสร็จสิ้นแล้ว"])},
  "827": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Requests"])},
  "828": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Advisees"])},
  "829": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Approval"])},
  "830": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คุณสมบัติเขตข้อมูลผู้ใช้"])},
  "831": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กำหนดค่าเริ่มต้นในเทมเพลต"])},
  "832": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กำหนดรูปแบบเขตข้อมูล"])},
  "833": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ค่า"])},
  "834": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รายการข้อมูล"])},
  "835": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ภาษา"])},
  "836": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไทย/อังกฤษ"])},
  "837": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไทย"])},
  "838": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["อังกฤษ"])},
  "839": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["จัดการกลุ่มผู้ใช้"])},
  "840": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เลือกจากผู้สอน"])},
  "841": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กำหนดผู้ประสานงานรายวิชา"])},
  "842": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผู้ประสานงานรายวิชา"])},
  "843": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รอกำหนดผู้ประสานงานรายวิชา"])},
  "844": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รายละเอียดผู้ประสานงานรายวิชา"])},
  "845": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ทุกกลุ่ม"])},
  "846": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กำหนดเอง"])},
  "847": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เพิ่มผู้ประสานงานรายวิชา"])},
  "848": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เลือก"])},
  "849": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กลุ่มเรียน"])},
  "850": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กำหนดกรรมการคุมสอบ"])},
  "851": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รอกำหนดกรรมการคุมสอบ"])},
  "852": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["จำนวนกรรมการคุมสอบ"])},
  "853": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["กรรมการคุมสอบ ", _interpolate(_named("proctors_number")), " คน ต่อ ", _interpolate(_named("seats_number")), " ที่นั่ง"])},
  "854": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กรรมการคุมสอบ"])},
  "855": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*กรรมการคุมสอบที่กำหนดอาจจะไม่ได้คุมสอบทุกคน ทั้งนี้ขึ้นอยู่กับจำนวนผู้เรียนหลังการถอนรายวิชา*"])},
  "856": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ระเบียบกรรมการคุมสอบ"])},
  "857": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กำหนดชั่วโมงการสอน"])},
  "858": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ระเบียบการกำหนดชั่วโมงการสอน"])},
  "859": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["จำนวนครั้งต่อสัปดาห์"])},
  "860": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชั่วโมงต่อภาคการศึกษา"])},
  "861": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สัปดาห์ที่สอนต่อภาคการศึกษา"])},
  "862": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชั่วโมง"])},
  "863": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สัปดาห์"])},
  "864": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รายละเอียด"])},
  "865": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ครั้ง/สัปดาห์"])},
  "866": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ค้นหากลุ่มเรียน"])},
  "867": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รายละเอียดผู้สอน"])},
  "868": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แก้ไข"])},
  "869": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กลุ่มเรียน"])},
  "870": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["จำนวนกลุ่มเรียน ", _interpolate(_named("studygroups_number")), " กลุ่ม"])},
  "871": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ตัวกรอง"])},
  "872": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผู้สอนภายนอก"])},
  "873": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คุณยืนยันที่จะลบกลุ่มเรียน ใช่หรือไม่"])},
  "874": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กลุ่มเรียน ตัวอย่าง 1-3,5"])},
  "875": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ข้อความที่ต้องการแสดงในฟอร์ม"])},
  "876": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ข้อความ (ไทย)"])},
  "877": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ข้อความ (อังกฤษ)"])},
  "878": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไทย/อังกฤษ"])},
  "879": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ข้อความ "])},
  "880": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ข้อความ (อังกฤษ)"])},
  "881": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สัปดาห์บรรยาย"])},
  "882": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สัปดาห์ปฏิบัติ"])},
  "883": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กำหนดผู้สอน"])},
  "884": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คุณต้องการลบผู้เรียนออกจาก "])},
  "885": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["พบข้อผิดพลาด โปรดลองใหม่อีกครั้ง"])},
  "886": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ลองใหม่"])},
  "887": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กำหนดผู้เรียนเพิ่มเติม"])},
  "888": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การเปลี่ยนรูปแบบเขตข้อมูลทำให้ข้อมูลสูญหาย \\nคุณยืนยันที่จะเปลี่ยนรูปแบบเขตข้อมูล ใช่หรือไม่"])},
  "889": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["อาจารย์ผู้สอน"])},
  "890": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รายการนักศึกษาในที่ปรึกษาขอลาระหว่างเรียน"])},
  "891": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["จำนวน"])},
  "892": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["จำนวนการลา"])},
  "893": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คาบ"])},
  "894": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รอผู้สอนอนุมัติ"])},
  "895": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผู้สอนไม่อนุมัติ"])},
  "896": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผู้สอนอนุมัติ"])},
  "897": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คำร้องขอ"])},
  "898": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GPA"])},
  "899": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กรุณากรอกเฉพาะตัวเลข หรือ ทศนิยม"])},
  "900": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สร้างเทมเพลต"])},
  "901": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เลือกเทมเพลต"])},
  "902": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["หน่วยงาน"])},
  "903": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชื่อไฟล์ซ้ำ"])},
  "904": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("fieldname")), "นี้มีอยู่แล้ว กรุณาใช้ข้อมูลอื่น"])},
  "905": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เกรดเฉลี่ยสะสม"])},
  "906": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เลือกเทมเพลต"])},
  "907": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thai student template default"])},
  "908": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Foreign student template default"])},
  "909": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Staff template default "])},
  "910": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ระเบียนประวัติ"])},
  "911": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ข้อมูลส่วนบุคคล"])},
  "912": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ข้อมูลติดต่อ"])},
  "913": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ข้อมูลการศึกษา"])},
  "914": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ข้อมูลครอบครัว"])},
  "915": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ข้อมูลสำรวจ"])},
  "916": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ข้อมูลบัณฑิต"])},
  "917": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เลขที่บัตรประชาชน"])},
  "918": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชื่อกลาง (ไทย)"])},
  "919": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชื่อกลาง (อังกฤษ)"])},
  "920": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รูปโปรไฟล์"])},
  "921": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วันเกิด"])},
  "922": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["หมู่เลือด"])},
  "923": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เพศ"])},
  "924": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ศาสนา"])},
  "925": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สัญชาติ"])},
  "926": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เชื้อชาติ"])},
  "927": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["จังหวัดเกิด"])},
  "928": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["โรคประจำตัว"])},
  "929": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["บันทึก"])},
  "930": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["อีเมลมหาวิทยาลัย"])},
  "931": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["อีเมลส่วนตัว"])},
  "932": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ที่อยู่ตามบัตรประชาชน"])},
  "933": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไลน์ไอดี"])},
  "934": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["บ้านเลขที่หรือชื่ออาคาร"])},
  "935": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ถนน, ซอย, หมู่ที่"])},
  "936": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เขต/อำเภอ"])},
  "937": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แขวง/ตำบล"])},
  "938": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ที่อยู่ปัจจุบัน"])},
  "939": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ที่อยู่จัดส่งเอกสารสำคัญ"])},
  "940": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สถานะนักศึกษา"])},
  "941": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คะแนนความดี"])},
  "942": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ระดับการศึกษา"])},
  "943": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชื่อปริญญา"])},
  "944": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ข้อมูลการเข้าศึกษา"])},
  "945": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วัน/เดือน/ปี เข้าศึกษา"])},
  "946": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ปีการเข้าศึกษา"])},
  "947": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ภาคการเข้าศึกษา"])},
  "948": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ประเภทการเข้าศึกษา"])},
  "949": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ประวัติการศึกษา"])},
  "950": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สถาบันการศึกษาที่จบ"])},
  "951": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ที่ตั้งสถาบัน"])},
  "952": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เพิ่มประวัติการศึกษา"])},
  "953": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ข้อมูลผู้ปกครอง"])},
  "954": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สถานภาพสมรสบิดา-มารดา"])},
  "955": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["หย่าร้าง"])},
  "956": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สมรส"])},
  "957": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["อยู่ด้วยกัน"])},
  "958": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แยกกันอยู่"])},
  "959": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["มีชีวิตอยู่"])},
  "960": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ถึงแก่กรรม"])},
  "961": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่ทราบข้อมูล"])},
  "962": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["อาชีพ"])},
  "963": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รายได้ต่อเดือน"])},
  "964": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ข้อมูลบิดา"])},
  "965": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ข้อมูลมารดา"])},
  "966": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ข้อมูลพี่น้อง (รวมตัวเองด้วย)"])},
  "967": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["จำนวนพี่น้อง (รวมตัวเองด้วย)"])},
  "968": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["จำนวนพี่น้องที่ทำงานแล้ว"])},
  "969": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["จำนวนพี่น้องที่กำลังศึกษา"])},
  "970": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สถานภาพของบัณฑิต"])},
  "971": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คุณเข้าร่วมพิธีพระราชทานปริญญาบัตร ใช่หรือไม่"])},
  "972": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เข้าร่วม"])},
  "973": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่เข้าร่วม"])},
  "974": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["จังหวัดที่บัณฑิตทำงาน"])},
  "975": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คุณมีอุปกรณ์สำหรับเรียนออนไลน์ ใช่หรือไม่"])},
  "976": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["มี"])},
  "977": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่มี"])},
  "978": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คุณมีอินเทอร์เน็ตสำหรับเรียนออนไลน์ ใช่หรือไม่"])},
  "979": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ถ้าคุณไม่มีความพร้อมในข้อ 1 หรือ 2 ท่านสามารถมาเรียนที่มหาวิทยาลัยได้ ใช่หรือไม่"])},
  "980": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ได้"])},
  "981": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่ได้"])},
  "982": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คุณเป็นบุตรคนแรกของครอบครัว ใช่หรือไม่"])},
  "983": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ใช้ข้อมูลผู้ปกครอง"])},
  "984": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ใช้ที่อยู่ตามบัตรประชาชน"])},
  "985": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["หนังสือเดินทาง"])},
  "986": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เลขที่หนังสือเดินทาง"])},
  "987": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สถานที่เกิด"])},
  "988": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สถาบันที่จบการศึกษา"])},
  "989": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ประเทศ"])},
  "990": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ที่อยู่ปัจจุบันในประเทศไทย"])},
  "991": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ที่อยู่ตามภูมิลำเนา"])},
  "992": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ข้อมูลที่อยู่"])},
  "993": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ข้อมูลที่อยู่บุคคลอ้างอิง"])},
  "994": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ข้อมูลที่อยู่บุคคลอ้างอิงในประเทศไทย"])},
  "995": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ข้อมูลญาติที่ใกล้ชิดที่สุด"])},
  "996": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ความสัมพันธ์"])},
  "997": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["จังหวัด/เมือง"])},
  "998": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รัฐ/เขต"])},
  "999": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ใช้ข้อมูลที่อยู่ปัจจุบันในประเทศไทย"])},
  "1000": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ที่อยู่จัดส่งเอกสารสำคัญในประเทศไทย"])},
  "1001": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สถานภาพ"])},
  "1002": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รหัสพนักงาน"])},
  "1003": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ข้อมูลตำแหน่งงาน"])},
  "1004": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ประเภทตำแหน่ง"])},
  "1005": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ปฏิบัติการวิชาชีพและบริหารทั่วไป"])},
  "1006": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["บริหารวิชาการ"])},
  "1007": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วิชาการ"])},
  "1008": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ลูกจ้างชั่วคราวรายเดือนกรณีพิเศษ"])},
  "1009": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ลูกจ้างชั่วคราว"])},
  "1010": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ฝ่าย"])},
  "1011": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สาขาวิชา"])},
  "1012": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สิทธิ์การใช้งาน"])},
  "1013": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วันที่เริ่มดำรงตำแหน่ง"])},
  "1014": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วันที่สิ้นสุดดำรงตำแหน่ง"])},
  "1015": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ประเภทการแสดงผล"])},
  "1016": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ดรอปดาวน์ - ค้นหา"])},
  "1017": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ดรอปดาวน์ - ค้นหา - เช็คบ็อกซ์"])},
  "1018": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ดรอปดาวน์"])},
  "1019": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ดรอปดาวน์ - เช็คบ็อกซ์"])},
  "1020": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เช็คบ็อกซ์"])},
  "1021": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เรดิโอบัตทอน"])},
  "1022": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชื่อหมวดหมู่ (EN)"])},
  "1023": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชื่อหมวดหมู่"])},
  "1024": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กรุณาเลือกเทมเพลต"])},
  "1025": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ข้อมูลที่อยู่จัดส่งเอกสารสำคัญจะสูญหาย\\nคุณต้องการเปลี่ยนเป็นข้อมูลตามบัตรประชาชน ใช่หรือไม่"])},
  "1026": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ข้อมูลในฟอร์มยังไม่ถูกบันทึก คุณต้องการบันทึกข้อมูล ใช่หรือไม่"])},
  "1027": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การบันทึกข้อมูลสำเร็จ"])},
  "1028": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กรุณาเลือกข้อมูลประเภทรูปภาพเท่านั้น (.jpeg)"])},
  "1029": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รูปถ่ายสี หน้าตรงไม่สวมแว่นตาทุกชนิด"])},
  "1030": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ฉากหลังสีฟ้า แต่งชุดพิธีการ"])},
  "1031": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไฟล์รูปนามสกุล .jpg เท่านั้น!"])},
  "1032": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ขนาดรูปถ่าย 2 นิ้ว (4.2×5.5 ซม.)"])},
  "1033": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ตัวอย่างรูปถ่าย"])},
  "1034": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ข้อกำหนด"])},
  "1035": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["โครงการรับนักศึกษา"])},
  "1036": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แฟ้มสะสมผลงาน"])},
  "1037": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["โควต้าพื้นที่ภาคใต้"])},
  "1038": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การรับเข้า"])},
  "1039": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รับตรง"])},
  "1040": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ทุน"])},
  "1041": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ทุนยกเว้นค่าธรรมเนียม"])},
  "1042": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ปริญญาตรี"])},
  "1043": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ปริญญาโท"])},
  "1044": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ปริญญาเอก"])},
  "1045": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ประกาศนียบัตร"])},
  "1046": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผู้เข้าร่วมเรียน"])},
  "1047": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผู้เข้าร่วมสอบ"])},
  "1048": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ประกาศนียบัตรวิชาชีพ (ปวช.)"])},
  "1049": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ประกาศนียบัตรวิชาชีพชั้นสูง (ปวส.)"])},
  "1050": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["มัธยมศึกษาตอนปลาย"])},
  "1051": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การศึกษานอกระบบและการศึกษาตามอัธยาศัย"])},
  "1052": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ภาคใต้"])},
  "1053": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ภาคกลาง"])},
  "1054": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ภาคตะวันออก"])},
  "1055": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ภาคตะวันออกเฉียงเหนือ"])},
  "1056": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ภาคเหนือ"])},
  "1057": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กรุงเทพฯและปริมณฑล"])},
  "1058": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ภาคตะวันตก"])},
  "1059": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่ระบุ"])},
  "1060": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["บิดาถึงแก่กรรม"])},
  "1061": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["มารดาถึงแก่กรรม"])},
  "1062": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["บิดาและมารดาถึงแก่กรรม"])},
  "1063": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["บิดาแต่งงานใหม่"])},
  "1064": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["มารดาแต่งงานใหม่"])},
  "1065": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["บิดาและมารดาแต่งงานใหม่"])},
  "1066": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["หยุดปฎิบัติงานชั่วคราว"])},
  "1067": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผู้อุปถัมป์"])},
  "1068": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รับราชการ"])},
  "1069": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["พนักงานรัฐวิสาหกิจ"])},
  "1070": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["พนักงานบริํษัทเอกชน"])},
  "1071": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ธุรกิจส่วนตัว/เจ้าของบริษัท/รับจ้าง"])},
  "1072": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เกษตกร/ประมง"])},
  "1073": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["พนักงานราชการ/ลูกจ้างหน่วยงานราชการ"])},
  "1074": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่มีรายได้/ไม่มีอาชีพ"])},
  "1075": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["อื่น ๆ"])},
  "1076": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่มีรายได้"])},
  "1077": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["น้อยกว่า 12,500 บาทต่อเดือน"])},
  "1078": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["12,500-25,000 บาทต่อเดือน"])},
  "1079": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" มากกว่า 25,000 บาทต่อเดือน"])},
  "1080": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["นักศึกษาปัจจุบัน"])},
  "1081": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รักษาสภาพนักศึกษา"])},
  "1082": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ลาพักการศึกษา"])},
  "1083": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ลาพัก/รักษาสภาพ"])},
  "1084": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ถูกสั่งพัก/รักษาสภาพ"])},
  "1085": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รออนุมัติจบการศึกษา"])},
  "1086": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สำเร็จการศึกษา"])},
  "1087": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["พ้นสภาพนักศึกษา (ลาออก)"])},
  "1088": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["พ้นสภาพนักศึกษา (ไม่มาลงทะเบียน / รักษาสภาพ)"])},
  "1089": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["พ้นสภาพนักศึกษา (เสียชีวิต)"])},
  "1090": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["พ้นสภาพนักศึกษา (ไม่ชำระค่าธรรมเนียม)"])},
  "1091": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["พ้นสภาพนักศึกษา (สละสิทธิ์การเข้าเป็นนักศึกษา)"])},
  "1092": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["พ้นสภาพนักศึกษา (GPAX ต่ำกว่า 1.00)"])},
  "1093": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["พ้นสภาพนักศึกษา (GPAX ต่ำกว่า 1.50)"])},
  "1094": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["พ้นสภาพนักศึกษา (GPAX ต่ำกว่า 2.00 สี่ภาคการศึกษาต่อเนื่อง)"])},
  "1095": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["พ้นสภาพนักศึกษา (สอบประมวลความรู้ไม่ผ่าน 2 ครั้ง)"])},
  "1096": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["พ้นสภาพนักศึกษา (ระยะเวลาศึกษาเกินระยะเวลาของหลักสูตร)"])},
  "1097": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["พ้นสภาพนักศึกษา (ผลการเรียน Fทุกรายวิชาหลังสิ้นภาคการเรียนที่ 1)"])},
  "1098": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["พ้นสภาพนักศึกษา (GPAX ต่ำกว่า 2.00 เมื่อสิ้นภาคการศึกษาที่ 6)"])},
  "1099": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สละสิทธิ์การเป็น นศ. รอขึ้นทะเบียนนักศึกษา"])},
  "1100": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รอขึ้นทะเบียนนักศึกษา (ปริญญาตรี)"])},
  "1101": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["พนักงาน"])},
  "1102": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ใช้ที่อยู่จัดส่งเอกสารสำคัญในประเทศไทย"])},
  "1103": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เลือกกลุ่มผู้ใช้"])},
  "1104": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กรุณาเลือกกลุ่มผู้ใช้"])},
  "1105": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เลือกกลุ่มผู้ใช้"])},
  "1106": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เบอร์โทรศัพท์มือถือ"])},
  "1107": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เบอร์โทรศัพท์ภายใน"])},
  "1108": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เพิ่มที่อยู่จัดส่ง"])},
  "1109": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ใช้ที่อยู่ผู้ปกครอง"])},
  "1110": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["นักศึกษา \\nปริญญาตรี"])},
  "1111": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["นักศึกษา \\nปริญญาโท"])},
  "1112": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["นักศึกษา \\nปริญญาเอก"])},
  "1113": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["นักศึกษา \\nปริญญาตรี ภาคปกติ"])},
  "1114": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["นักศึกษา \\nปริญญาตรี ภาคปกติ ไตรภาค"])},
  "1115": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["นักศึกษา \\nปริญญาตรี ภาคปกติ ทวิภาค"])},
  "1116": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["นักศึกษา \\nปริญญาโท ภาคปกติ"])},
  "1117": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["นักศึกษา \\nปริญญาโท ภาคพิเศษ"])},
  "1118": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["นักศึกษา \\nปริญญาเอก ภาคปกติ"])},
  "1119": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["นักศึกษา \\nปริญญาเอก ภาคพิเศษ"])},
  "1120": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["นักศึกษา \\nปริญญาตรี ไตรภาค"])},
  "1121": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["นักศึกษา \\nปริญญาตรี ทวิภาค"])},
  "1122": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กำหนดรายละเอียดการสอน"])},
  "1123": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กรุณากรอกข้อมูลเพื่อกำหนดรายละเอียดการสอน"])},
  "1124": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รอการกำหนดรายละเอียดการสอน"])},
  "1125": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กลุ่มเรียนทั้งหมด"])},
  "1126": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รอกำหนดผู้สอน"])},
  "1127": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กำหนดผู้สอนแล้ว"])},
  "1128": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กลุ่มที่เลือก"])},
  "1129": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผู้สอน"])},
  "1130": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชั่วโมงบรรยาย"])},
  "1131": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชั่วโมงปฏิบัติ"])},
  "1132": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชั่วโมงสอน"])},
  "1133": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กำหนดสัปดาห์การสอน"])},
  "1134": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่สอน"])},
  "1135": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ค้นหาผู้สอน"])},
  "1136": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เพิ่มผู้สอน"])},
  "1137": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กรุณากรอก (A-Z) (a-z) (ก-ฮ)"])},
  "1138": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กำหนดรายละเอียดกลุ่มเรียน"])},
  "1139": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กรุณากรอกข้อมูลรายละเอียดกลุ่มเรียน"])},
  "1140": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กำหนดกลุ่มเรียน"])},
  "1141": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กำหนดจำนวนที่นั่งเรียนต่อกลุ่ม"])},
  "1142": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กำหนดประเภทการจัดกลุ่มผู้เรียน"])},
  "1143": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["หลักสูตร, ชั้นปี, สำนักวิชา, กลุ่มสำนักวิชา"])},
  "1144": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เกรดของรายวิชาที่กำหนด"])},
  "1145": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผลคะแนนการสอบ"])},
  "1146": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เกรดเฉลี่ยสะสม (GPAX)"])},
  "1147": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผลการจัดกลุ่ม"])},
  "1148": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("Numberseats")), " ที่นั่งต่อกลุ่ม"])},
  "1149": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["หมายเลขกลุ่ม ต.ย. 1-4, 6, 7, 10"])},
  "1150": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กลุ่มที่"])},
  "1151": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รายละเอียดกลุ่ม"])},
  "1152": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["จำนวนย้าย"])},
  "1153": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["อัปโหลดผลคะแนนการสอบ"])},
  "1154": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ข้อกำหนดการอัปโหลดไฟล์"])},
  "1155": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("Filetypenumber")), ". ประเภทไฟล์จะต้องนามสกุล ", _interpolate(_named("Filetypename")), "\n"])},
  "1156": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("Filetypenumber")), ". หัวตารางจะต้องประกอบด้วย "])},
  "1157": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["\\n คอลัมน์ ", _interpolate(_named("Columnnumber")), ": ", _interpolate(_named("Columnname"))])},
  "1158": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["อัปโหลดไฟล์"])},
  "1159": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เทมเพลตไฟล์ผลคะแนนการสอบ"])},
  "1160": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["จำนวนนักศึกษาทั้งหมด"])},
  "1161": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กำหนดช่วงคะแนนการสอบ"])},
  "1162": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["จำนวนช่วงคะแนน"])},
  "1163": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เพิ่ม"])},
  "1164": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รายละเอียดเพิ่มเติม"])},
  "1165": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["จำนวนที่ต้องการย้าย"])},
  "1166": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["อยู่ระหว่างการย้าย"])},
  "1167": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ย้ายสำเร็จ"])},
  "1168": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ย้ายผู้เรียน"])},
  "1169": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["ย้ายไปที่กลุ่ม ", _interpolate(_named("Groupnumber"))])},
  "1170": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กลุ่ม"])},
  "1171": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ทั้งหมด"])},
  "1172": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ย้าย"])},
  "1173": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คน"])},
  "1174": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["จำนวนนักศึกษาจำแนกตามเกรดเฉลี่ยสะสม (GPAX)"])},
  "1175": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["ตั้งแต่ ", _interpolate(_named("startGPAX")), " ถึง ", _interpolate(_named("endGPAX")), " = ", _interpolate(_named("numberStudents")), " คน"])},
  "1176": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["\\n มากกว่า ", _interpolate(_named("startGPAX")), " ถึง ", _interpolate(_named("endGPAX")), " = ", _interpolate(_named("numberStudents")), " คน"])},
  "1177": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["ทั้งหมด = ", _interpolate(_named("totalStudents")), " คน"])},
  "1178": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กำหนดช่วงเกรดเฉลี่ยสะสม"])},
  "1179": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["จำนวนช่วงเกรดเฉลี่ยสะสม"])},
  "1180": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ตั้งแต่"])},
  "1181": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ถึง"])},
  "1182": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["มากกว่า"])},
  "1183": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ประวัติการจัดการรายวิชา"])},
  "1184": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กลุ่ม"])},
  "1185": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คน"])},
  "1186": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผู้เรียนเพิ่มเติม"])},
  "1187": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["ชั้นปี ", _interpolate(_named("year"))])},
  "1188": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["ปี ", _interpolate(_named("yearFirst")), " และ ปี ", _interpolate(_named("yearSecon"))])},
  "1189": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ขึ้นไป"])},
  "1190": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สอบบรรยาย (โดยผู้สอน)"])},
  "1191": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สอบปฏิบัติ (โดยผู้สอน)"])},
  "1192": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สอบบรรยาย (โดย ศบศ.)"])},
  "1193": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สอบปฏิบัติ (โดย ศบศ.)"])},
  "1194": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["กลุ่มละ ", _interpolate(_named("numberSeats")), " ที่นั่ง"])},
  "1195": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผู้ไม่มีสิทธิ์ลงทะเบียน"])},
  "1196": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่พบประวัติการจัดการรายวิชา"])},
  "1197": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รอกำหนดรายละเอียดกลุ่มเรียน"])},
  "1198": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รายละเอียดการปิดรายวิชา"])},
  "1199": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แก้ไขการปิดรายวิชา"])},
  "1200": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ค้นหาจากรหัสวิชา"])},
  "1201": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["กรุณากรอกมากกว่า ", _interpolate(_named("numberCharacters")), " ตัวอักษร"])},
  "1202": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่มีสิทธิ์ลงทะเบียนด้วยเหตุผลอื่น"])},
  "1203": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["กรุณากรอกจำนวนผู้มีสิทธิ์ไม่เกิน ", _interpolate(_named("numberProspectivestudents"))])},
  "1204": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เหตุผลอื่น"])},
  "1205": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รายวิชาบังคับเรียน"])},
  "1206": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รอกำหนด"])},
  "1207": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผู้ไม่มีสิทธิ์ลงทะเบียนทั้งหมด"])},
  "1208": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("first")), " จาก ", _interpolate(_named("total")), " รายการ "])},
  "1209": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การลบผู้เรียนทำให้ข้อมูลในกลุ่มเรียนสูญหาย \\nคุณยืนยันที่จะลบ ใช่หรือไม่"])},
  "1210": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การสำรองสำหรับผู้เรียนตกค้าง"])},
  "1211": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การสำรองที่นั่งสำหรับผู้ยื่นคำร้อง"])},
  "1212": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กำหนดผู้เรียน"])},
  "1213": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แยกหลักสูตร"])},
  "1214": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คละหลักสูตร"])},
  "1215": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แยกชั้นปี"])},
  "1216": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คละชั้นปี"])},
  "1217": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สำนักวิชา หรือ หลักสูตร"])},
  "1218": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รหัสนักศึกษา หรือ ชื่อ-นามสกุล"])},
  "1219": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["รวมผู้เรียนตกค้าง (", _interpolate(_named("number")), " ที่นั่ง)"])},
  "1220": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["รวมผู้เรียนยื่นคำร้อง (", _interpolate(_named("number")), " ที่นั่ง)"])},
  "1221": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["จำนวนผู้เรียนสูงสุดที่สามารถกำหนดได้ไม่เกิน ", _interpolate(_named("numberStudent")), " คน"])},
  "1222": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["กรุณากรอกจำนวนผู้เรียนตั้งแต่ ", _interpolate(_named("numberStudent")), " คนขึ้นไป"])},
  "1223": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การจัดการรายวิชาเรียนร่วม"])},
  "1224": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่พบข้อมูล"])},
  "1225": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ทุกคน"])},
  "1226": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การเปลี่ยนรายละเอียดผู้รับเป็น\"ทุกคน\" ทำให้ข้อมูลที่เคยกำหนดแบบกลุ่ม/กำหนดรายบุคคลถูกลบ\\nคุณยืนยันที่จะเปลี่ยน ใช่หรือไม่"])},
  "1227": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เลือกผู้เรียนทุกคน"])},
  "1228": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กำหนดจำนวนผู้เรียน"])},
  "1229": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กำหนด"])},
  "1230": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["การเปลี่ยนรายวิชา ", _interpolate(_named("Subject_codes")), " ", _interpolate(_named("Subjects_name")), " เป็นวิชาเรียนร่วม ทำให้ข้อมูลเดิมที่กำหนดไว้ถูกลบ\\nคุณยืนยันที่จะเปลี่ยน ใช่หรือไม่"])},
  "1231": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่สามารถอัปโหลดไฟล์นี้ได้ กรุณาแก้ไขตามรายการต่อไปนี้"])},
  "1232": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["กรอกข้อมูลใน คอลัมน์ ", _interpolate(_named("colunm_name")), " แถวที่ ", _interpolate(_named("row_number"))])},
  "1233": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ตรวจสอบความถูกต้องของหัวตาราง"])},
  "1234": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ขออภัย!  \\nเนื่องจากมีผู้ใช้งานจำนวนมาก ส่งผลต่อการใช้งานบางช่วงเวลา เรากำลังทำการแก้ไขปัญหา และพยายามให้บริการกลับมาใช้งานได้เร็วที่สุด"])},
  "1235": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["จำนวนผู้เรียน"])},
  "1236": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["เลือก ", _interpolate(_named("first")), " จาก ", _interpolate(_named("total")), " รายการ "])},
  "1237": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กำหนดจำนวนผู้เรียนเพิ่มเติม"])},
  "1238": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recommended"])},
  "1239": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กลุ่มเรียนนี้มีผู้เรียนจากหลักสูตรและชั้นปีตรงกับที่ท่านเลือก"])},
  "1240": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["จำนวนที่นั่งเพียงพอ"])},
  "1241": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กลุ่มเรียนนี้มีจำนวนที่นั่งเพียงพอสำหรับจำนวนที่ท่านเลือก"])},
  "1242": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["จำนวนที่นั่งไม่เพียงพอ"])},
  "1243": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กลุ่มเรียนนี้มีจำนวนที่นั่งไม่เพียงพอสำหรับจำนวนที่ท่านเลือก"])},
  "1244": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เพิ่มกลุ่มใหม่"])},
  "1245": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รายการผู้เรียนเพิ่มเติม"])},
  "1246": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["จำนวนผู้เรียนที่ต้องการเพิ่ม"])},
  "1247": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["บางส่วนของผู้เรียนกลุ่มนี้ได้รับสิทธิ์ลงทะเบียนแล้ว \\nการเพิ่มผู้เรียนอาจทำให้จำนวนสิทธิ์ในการลงทะเบียนเปลี่ยนแปลง"])},
  "1248": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["จำนวนผู้เรียนเพิ่มเติม (ที่นั่ง)"])},
  "1249": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ว่าง"])},
  "1250": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ค้นหาหมายเลขกลุ่ม"])},
  "1251": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["จำนวนผู้เรียนเพิ่มเติม"])},
  "1252": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["หลักสูตร"])},
  "1253": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สำนักวิชา"])},
  "1254": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ค้นหาสำนักวิชา หลักสูตร หรือ ชั้นปี"])},
  "1255": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["จำนวนผู้เรียนต่อกลุ่ม"])},
  "1256": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กำหนดรายละเอียดกลุ่มเรียน"])},
  "1257": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ให้สิทธิ์ลงทะเบียนแล้ว"])},
  "1258": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กำหนด"])},
  "1259": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การลบผู้เรียนที่มีการกำหนดกลุ่มเรียนแล้ว จะส่งผลให้ข้อมูลผู้เรียนในกลุ่มเรียนนั้นสูญหาย\\nคุณยืนยันจะลบผู้เรียน ใช่หรือไม่"])},
  "1260": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ระบบกำลังประมวลผล กรุณารอสักครู่"])},
  "1261": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["จำนวนกลุ่มเรียนที่สามารถเปิดสอนได้พร้อมกันในช่วงเวลาเดียวกัน"])},
  "1262": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กำหนดวิธีการจัดกลุ่มเรียน"])},
  "1263": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วิธีการจัดกลุ่มเรียน"])},
  "1264": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["จำนวนกลุ่ม"])},
  "1265": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผู้จัดสอบ"])},
  "1266": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ระยะเวลาการสอบ"])},
  "1267": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ข้อมูลเพิ่มเติม"])},
  "1268": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ข้อมูลในฟอร์มยังไม่ถูกบันทึก คุณต้องการบันทึกข้อมูล ใช่หรือไม่"])},
  "1269": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["กรุณากรอกรหัสนักศึกษาให้ครบ ", _interpolate(_named("number")), " ตัว"])},
  "1270": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["กรุณากรอกรหัสพนักงานให้ครบ ", _interpolate(_named("number")), " ตัว"])},
  "1271": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เพิ่มหน่วยงานย่อย"])},
  "1272": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เพิ่มหน่วยงานวิชาการย่อย"])},
  "1273": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["หากต้องการแก้ไข โปรดติดต่อเจ้าหน้าที่ CES system"])},
  "1274": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กำหนดรายละเอียดข้อความ"])},
  "1275": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กำหนดการส่ง"])},
  "1276": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กำหนดข้อมูลติดต่อกลับ"])},
  "1277": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผู้แจ้งข้อความ"])},
  "1278": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วิชาเอก"])},
  "1279": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รายวิชาโท"])},
  "1280": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คุณระบุผู้เรียนมากกว่าจำนวนที่กำหนด\\nคุณยืนยันที่จะเพิ่มจำนวนผู้เรียน ใช่หรือไม่"])},
  "1281": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผู้เรียนทุกคนในหลักสูตรและชั้นปีที่กำหนด"])},
  "1282": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กำหนดผู้เรียน"])},
  "1283": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["จำนวนผู้เรียนที่กำหนดแล้ว ", _interpolate(_named("selectstudent")), "/", _interpolate(_named("maxstudent")), " คน"])},
  "1284": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["จำนวนผู้เรียนที่ระบุมากกว่าที่กำหนด"])},
  "1285": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["กรุณากรอกจำนวนผู้เรียนเพิ่มเติมตั้งแต่ ", _interpolate(_named("min")), " ถึง ", _interpolate(_named("max"))])},
  "1286": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["คุณกรอกรหัสผ่านผิดเกิน ", _interpolate(_named("LONGINFAILNUM")), " ครั้ง กรุณาติดต่อเจ้าหน้าที่ โทร ", _interpolate(_named("LockContact"))])},
  "1287": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["คุณกรอกรหัสผ่านผิดเกิน ", _interpolate(_named("LONGINFAILNUM")), " ครั้ง กรุณาติดต่อเจ้าหน้าที่ CES system โทร ", _interpolate(_named("LockContact"))])},
  "1288": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รับทราบ"])},
  "1289": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คำร้องขอตรวจสอบคะแนน"])},
  "1290": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กรุณากรอกคำร้องขอตรวจสอบคะแนน"])},
  "1291": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ข้อมูลสำหรับติดต่อ"])},
  "1292": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ประเภทคำร้องขอ"])},
  "1293": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คำร้องที่ใช้บ่อย"])},
  "1294": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คำร้องอื่นๆ"])},
  "1295": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชื่อคำร้อง"])},
  "1296": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ค้นหาผู้เรียน"])},
  "1297": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["จำนวนผู้เรียนที่กำหนดแล้ว"])},
  "1298": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รหัสนักศึกษา หรือ ชื่อ-นามสกุล หรือ หลักสูตร หรือ เกรดเฉลี่ยสะสม"])},
  "1299": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["จำนวน"])},
  "1300": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สร้างคำร้อง"])},
  "1301": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ค้นหาประเภทคำร้อง"])},
  "1302": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การยื่นคำร้อง"])},
  "1303": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ค้นหาจาก"])},
  "1304": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["นักศึกษาในที่ปรึกษา"])},
  "1305": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รายวิชาที่สอน"])},
  "1306": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กรองจาก"])},
  "1307": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เงื่อนไข"])},
  "1308": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ประเภทผู้รับ"])},
  "1309": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["จำนวนอาจารย์"])},
  "1310": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["อาจารย์ที่ใช้แอปพลิเคชัน"])},
  "1311": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["นักศึกษาที่ใช้แอปพลิเคชัน"])},
  "1312": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผู้เรียนนี้ถูกจัดกลุ่มเรียนแล้ว"])},
  "1313": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กรณีจำนวนผู้เรียนที่มีอยู่จริงน้อยกว่าที่ระบุ จำนวนผู้เรียนเพิ่มเติมจะเท่ากับจำนวนที่มีอยู่จริง"])},
  "1314": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชื่อผู้ใช้"])},
  "1315": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รหัสผ่าน"])},
  "1316": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เกรดเฉลี่ยสะสมน้อยกว่า"])},
  "1317": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["นักศึกษาในสำนักวิชา หรือ หลักสูตร"])},
  "1318": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["นักศึกษาที่สอน"])},
  "1319": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ปรกติ"])},
  "1320": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ค้างชำระ"])},
  "1321": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["พ้นสภาพนักศึกษาชั่วคราว"])},
  "1322": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["พ้นสภาพนักศึกษา"])},
  "1323": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เกรดเฉลี่ยสะสมน้อยกว่าเท่ากับ"])},
  "1324": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชื่อ-นามสกุล"])},
  "1325": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ค้นหาผู้รับ"])},
  "1326": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รายการผู้รับ"])},
  "1327": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การลบผู้ไม่มีสิทธิ์ลงทะเบียนทำให้ข้อมูลสูญหาย \\nคุณยืนยันที่จะลบ ใช่หรือไม่"])},
  "1328": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กรุณากรอกบัญชีเฟซบุ๊กตามรูปแบบ https://www.facebook/messenger.com/t/ \\nหรือ https://www.messenger.com/t/ ห้ามเว้นวรรค"])},
  "1329": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การเปลี่ยนผู้รับทำให้ข้อมูลสูญหาย คุณยืนยันที่จะเปลี่ยนผู้รับ ใช่หรือไม่"])},
  "1330": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รับทราบและดำเนินการตรวจสอบ"])},
  "1331": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["บันทึกร่างการตรวจสอบคะแนน"])},
  "1332": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รอคณบดีพิจารณา"])},
  "1333": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คณบดีไม่เห็นชอบ"])},
  "1334": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คณบดีเห็นชอบ"])},
  "1335": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รายละเอียดคำร้อง"])},
  "1336": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ลงทะเบียนเรียน"])},
  "1337": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รายวิชา"])},
  "1338": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผลการตรวจสอบคะแนน"])},
  "1339": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รายละเอียดคะแนน"])},
  "1340": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผลการตรวจสอบ"])},
  "1341": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คะแนนถูกต้อง"])},
  "1342": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คะแนนไม่ถูกต้อง"])},
  "1343": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รายละเอียดการแก้ไขคะแนน"])},
  "1344": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ส่งถึงคณบดี"])},
  "1345": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผลการพิจารณาจากคณบดี"])},
  "1346": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ความเห็นเพิ่มเติม"])},
  "1347": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เห็นชอบ"])},
  "1348": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่เห็นชอบ"])},
  "1349": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผลการพิจารณา"])},
  "1350": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กำหนดเงื่อนไขการลงทะเบียน"])},
  "1351": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เพิ่มเงื่อนไข"])},
  "1352": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["นักศึกษาที่ได้รับเกรด (A ถึง D)"])},
  "1353": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["นักศึกษาที่ได้รับเกรด S"])},
  "1354": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["นักศึกษาที่ได้รับเกรดใด ๆ (A ถึง F)"])},
  "1355": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เรียนควบคู่กับรายวิชา"])},
  "1356": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["นักศึกษาชั้นปี"])},
  "1357": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สอบผ่านรายวิชาที่แต่ละหลักสูตรกำหนด"])},
  "1358": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สอบผ่าน Placement Test ที่หลักสูตร/มหาวิทยาลัยกำหนด"])},
  "1359": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เป็นนักศึกษาที่ได้รับความเห็นชอบจากคณะกรรมการประจำสำนักวิชา"])},
  "1360": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["นักศึกษาที่ได้รับความเห็นชอบจากอาจารย์ในหลักสูตร"])},
  "1361": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["จากรายวิชา"])},
  "1362": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รายการเงื่อนไข"])},
  "1363": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["หรือ"])},
  "1364": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เงื่อนไขการลงทะเบียน"])},
  "1365": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กำหนดเงื่อนไข"])},
  "1366": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เงื่อนไข"])},
  "1367": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่มีกำหนด"])},
  "1368": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผู้เรียนที่ถูกกำหนดแล้ว"])},
  "1369": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ประเภทของผู้มีสิทธิ์ลงทะเบียน"])},
  "1370": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กำลังดำเนินการ"])},
  "1371": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กลุ่ม"])},
  "1372": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["โทรศัพท์"])},
  "1373": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เพิ่มผู้เรียน"])},
  "1374": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["จำนวนผู้เรียน"])},
  "1375": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เพิ่มผู้เรียนจากรายวิชาเทียบเท่า"])},
  "1376": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เพิ่มผู้เรียนจากรายวิชาอื่น ๆ"])},
  "1377": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เพิ่มผู้เรียนจากผู้เรียนที่ตกค้าง "])},
  "1378": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผู้เรียนที่ได้รับสิทธิ์ลงทะเบียน"])},
  "1379": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เพิ่มผู้เรียนจากผู้เรียนที่ยื่นคำร้อง "])},
  "1380": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผู้เรียนที่ยื่นคำร้อง "])},
  "1381": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รวม"])},
  "1382": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผู้เรียนจากรายวิชาอื่น ๆ"])},
  "1383": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["หมวดวิชาเฉพาะ"])},
  "1384": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กลุ่มวิชาโท"])},
  "1385": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สร้างรายวิชา"])},
  "1386": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กำหนดรายวิชา"])},
  "1387": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชื่อรายวิชา (ไทย)"])},
  "1388": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชื่อรายวิชา (อังกฤษ)"])},
  "1389": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชื่อรายวิชา "])},
  "1390": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คำอธิบายรายวิชา (ไทย)"])},
  "1391": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คำอธิบายรายวิชา (อังกฤษ)"])},
  "1392": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คำอธิบายรายวิชา"])},
  "1393": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["หน่วยกิต (บรรยาย - ปฏิบัติ - ศึกษาด้วยตนเอง)"])},
  "1394": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ประเภทรายวิชา"])},
  "1395": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชั่วโมงศึกษาด้วยตนเอง"])},
  "1396": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["หน่วยกิต"])},
  "1397": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ประเภทห้องเรียน"])},
  "1398": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ห้องเรียนบรรยาย"])},
  "1399": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ห้องเรียนปฏิบัติ"])},
  "1400": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ระบบเกรดที่ใช้"])},
  "1401": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กำหนดค่าเริ่มต้นการนับหน่วยกิตในโครงสร้างหลักสูตร"])},
  "1402": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["นับหน่วยกิต"])},
  "1403": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่นับหน่วยกิต"])},
  "1404": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เปิดสอนสำหรับ"])},
  "1405": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เฉพาะหลักสูตร"])},
  "1406": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เฉพาะสำนักวิชา"])},
  "1407": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ทั้งมหาวิทยาลัย"])},
  "1408": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["มีเงื่อนไข"])},
  "1409": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่มีเงื่อนไข"])},
  "1410": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SDG"])},
  "1411": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ด้านสิ่งแวดล้อม"])},
  "1412": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ด้านสังคม"])},
  "1413": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ด้านวัฒนธรรม"])},
  "1414": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ด้านเศรษฐศาสตร์"])},
  "1415": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["หมายเหตุ"])},
  "1416": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รายวิชาภาคทฤษฎีและหรือรายวิชาภาคปฏิบัติ"])},
  "1417": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รายวิชาฝึกงานหรือการฝึกภาคสนาม"])},
  "1418": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รายวิชาโครงงาน"])},
  "1419": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รายวิชาวิชาค้นคว้าอิสระ"])},
  "1420": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เพิ่มผู้เรียนจากหลักสูตร"])},
  "1421": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผู้เรียนจากหลักสูตร"])},
  "1422": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รอกำหนดสิทธิ์"])},
  "1423": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กำหนดรายละเอียดผู้เรียน"])},
  "1424": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กำหนดจำนวนผู้เรียน"])},
  "1425": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ท่านได้เพิ่มสิทธิ์การลงทะเบียนให้กับผู้เรียนดังต่อไปนี้แล้ว"])},
  "1426": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กรุณาเลือกผู้เรียนใหม่"])},
  "1427": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("fieldName")), " นี้มีอยู่ในระบบแล้ว กรุณาใช้ ", _interpolate(_named("fieldName")), " อื่น"])},
  "1428": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["บันทึกข้อมูลไม่สำเร็จ โปรดติดต่อเจ้าหน้าที่ CES system โทร ", _interpolate(_named("LockContact"))])},
  "1429": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["ลบข้อมูลไม่สำเร็จ โปรดติดต่อเจ้าหน้าที่ CES system\nโทร ", _interpolate(_named("LockContact"))])},
  "1430": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["แก้ไขข้อมูลไม่สำเร็จ โปรดติดต่อเจ้าหน้าที่ CES system\nโทร ", _interpolate(_named("LockContact"))])},
  "1431": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["พบข้อผิดพลาด โปรดติดต่อเจ้าหน้าที่ CES system \\nโทร ", _interpolate(_named("LockContact"))])},
  "1432": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผู้เรียนตกค้าง"])},
  "1433": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รายการผู้เรียน"])},
  "1434": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผู้ประสานงานรายวิชาบันทึกร่างผลการตรวจสอบ"])},
  "1435": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผู้ประสานงานรายวิชารับทราบและดำเนินการตรวจสอบ"])},
  "1436": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ให้ผู้ประสานงานรายวิชาตรวจสอบคะแนนอีกครั้ง"])},
  "1437": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รอนักศึกษารับทราบผลการพิจารณา"])},
  "1438": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["นักศึกษารับทราบผลการพิจารณา"])},
  "1439": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คะแนนได้รับการตรวจสอบแล้ว รอนักศึกษารับทราบ"])},
  "1440": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คำร้องรอดำเนินการ"])},
  "1441": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผลการยื่นคำร้อง "])},
  "1442": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สถานะคำร้อง"])},
  "1443": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ดำเนินการเสร็จสิ้นแล้ว"])},
  "1444": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ดำเนินการสำเร็จ"])},
  "1445": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["บทบาท"])},
  "1446": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["คุณยืนยันที่จะอนุมัติคำร้อง จำนวน ", _interpolate(_named("totalRequest")), " รายการ ใช่หรือไม่"])},
  "1447": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รอผลการพิจารณา"])},
  "1448": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รายการคำร้องขอ"])},
  "1449": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คุณต้องการลบคำร้องนี้ ใช่หรือไม่"])},
  "1450": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คุณต้องการยกเลิกคำร้องนี้ ใช่หรือไม่"])},
  "1451": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การยกเลิกคำร้อง ทำให้ข้อมูลในฟอร์มสูญหาย คุณยืนยันที่จะยกเลิก ใช่หรือไม่"])},
  "1452": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คำร้องนี้ถูกบันทึกไปแล้ว"])},
  "1453": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คำร้องนี้ถูกลบไปแล้ว"])},
  "1454": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คำร้องนี้ไม่สามารถลบได้ เนื่องจากอยู่ในระหว่างการดำเนินการ"])},
  "1455": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["เพิ่ม", _interpolate(_named("Msg")), "สำเร็จ"])},
  "1456": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["ยกเลิก", _interpolate(_named("Msg")), "สำเร็จ"])},
  "1457": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["แก้ไข", _interpolate(_named("Msg")), "สำเร็จ"])},
  "1458": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เหตุผลการไม่ให้สิทธิ์ลงทะเบียน"])},
  "1459": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["บันทึก", _interpolate(_named("Msg")), "สำเร็จ"])},
  "1460": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ยื่นคำร้อง"])},
  "1461": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เสนอคำร้องต่อคณบดี"])},
  "1462": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เห็นชอบผลการตรวจสอบ"])},
  "1463": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รับทราบผลการตรวจสอบคะแนน"])},
  "1464": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["อยู่ระหว่างการตรวจสอบคะแนน"])},
  "1465": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ดำเนินการตรวจสอบคะแนนเสร็จแล้ว"])},
  "1466": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่มีการสอบ"])},
  "1467": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่กำหนด"])},
  "1468": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กำหนดสิทธิ์ลงทะเบียน"])},
  "1469": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่ให้สิทธิ์"])},
  "1470": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ให้สิทธิ์"])},
  "1471": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ที่นั่งเต็ม"])},
  "1472": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผู้สอนมีจำนวนไม่เพียงพอ"])},
  "1473": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["อุปกรณ์ไม่เพียงพอ"])},
  "1474": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กำหนดเปิดครั้งถัดไป"])},
  "1475": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รายการผู้เรียนตกค้างที่รอกำหนดสิทธิ์ลงทะเบียน"])},
  "1476": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["จำนวนผู้เรียนตกค้างรอกำหนดสิทธิ์ลงทะเบียน"])},
  "1477": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["จำนวนผู้เรียนตกค้างที่มีสิทธิ์ลงทะเบียน"])},
  "1478": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["จำนวนผู้เรียนตกค้างที่ไม่มีสิทธิ์ลงทะเบียนด้วยเหตุผลนี้"])},
  "1479": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รายการผู้เรียนตกค้างที่มีสิทธิ์ลงทะเบียน"])},
  "1480": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผู้ไม่มีสิทธิ์ลงทะเบียน"])},
  "1481": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รายการผู้เรียนตกค้างที่ไม่มีสิทธิ์ลงทะเบียน"])},
  "1482": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การลบทำให้ข้อมูลในรายการผู้เรียนนี้สูญหาย\\nคุณยืนยันที่จะลบ ใช่หรือไม่"])},
  "1483": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รายการคำร้องรอดำเนินการ"])},
  "1484": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["ส่ง", _interpolate(_named("Msg")), "สำเร็จ"])},
  "1485": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ข้อมูลนี้ถูกเพิ่มไปแล้ว"])},
  "1486": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รายการผู้เรียนยื่นคำร้องที่รอกำหนดสิทธิ์ลงทะเบียน"])},
  "1487": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["จำนวนผู้เรียนยื่นคำร้องรอกำหนดสิทธิ์ลงทะเบียน"])},
  "1488": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["จำนวนผู้เรียนยื่นคำร้องที่มีสิทธิ์ลงทะเบียน"])},
  "1489": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["จำนวนผู้เรียนยื่นคำร้องที่ไม่มีสิทธิ์ลงทะเบียนด้วยเหตุผลนี้"])},
  "1490": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รายการผู้เรียนยื่นคำร้องที่มีสิทธิ์ลงทะเบียน"])},
  "1491": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รายการผู้เรียนยื่นคำร้องที่ไม่มีสิทธิ์ลงทะเบียน"])},
  "1492": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รายละเอียดคำร้องขอลาระหว่างสอบปลายภาค"])},
  "1493": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รายละเอียดคำร้องขอตรวจสอบคะแนน"])},
  "1494": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["ระยะเวลาการสอบ ", _interpolate(_named("startdate")), "-", _interpolate(_named("enddate")), " "])},
  "1495": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ประเภทการลา"])},
  "1496": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ลากิจ"])},
  "1497": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ลาป่วย"])},
  "1498": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผู้ประสานงานรายวิชา หรือ ผู้สอน"])},
  "1499": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วันที่สอบ"])},
  "1500": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เหตุผลของการลา"])},
  "1501": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ที่อยู่ที่สามารถติดต่อได้"])},
  "1502": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ใบรับรองแพทย์"])},
  "1503": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ออกโดยโรงพยาบาลของรัฐ หรือ เอกชน เท่านั้น"])},
  "1504": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กำหนดผู้ประสานงานรายวิชา หรือ ผู้สอน"])},
  "1505": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผู้ประสานงานรายวิชา"])},
  "1506": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผู้สอน"])},
  "1507": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผู้เรียนยื่นคำร้องที่มีสิทธิ์ลงทะเบียน"])},
  "1508": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผู้เรียนยื่นคำร้องที่ไม่มีสิทธิ์ลงทะเบียน"])},
  "1509": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["ท่านได้เพิ่มสิทธิ์การลงทะเบียนให้กับผู้เรียน\\nในรายวิชา ", _interpolate(_named("SubjectCode")), " ", _interpolate(_named("SubjectName")), " ดังต่อไปนี้แล้ว"])},
  "1510": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ส่งคำร้องถึง"])},
  "1511": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ความเห็นของอาจารย์ที่ปรึกษา"])},
  "1512": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กรุณาแนบไฟล์ .xls, .xlsx เท่านั้น "])},
  "1513": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รออนุญาต"])},
  "1514": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["อนุญาต"])},
  "1515": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่อนุญาต"])},
  "1516": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["จำนวน"])},
  "1517": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ลาทั้งหมด"])},
  "1518": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รับทราบและดำเนินการให้ความเห็น \\n(อาจารย์ที่ปรึกษา)"])},
  "1519": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["บันทึกความเห็น \\n(อาจารย์ที่ปรึกษา)"])},
  "1520": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รับทราบและดำเนินการพิจารณา \\n(คณบดี)"])},
  "1521": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รับทราบและดำเนินการพิจารณา \\n(ผู้ประสานงานรายวิชา หรือ ผู้สอน)"])},
  "1522": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["บันทึกผลการพิจารณา \\n(คณบดี)"])},
  "1523": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["บันทึกผลการพิจารณา \\n(หัวหน้าสาขาปฏิบัติหน้าที่แทนคณบดี)"])},
  "1524": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ความเห็นผู้ประสานงานรายวิชา หรือ ผู้สอน"])},
  "1525": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วัน เวลาสอบ"])},
  "1526": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รายชื่อนักศึกษาที่รอพิจารณา"])},
  "1527": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คำชี้แจงเพิ่มเติม"])},
  "1528": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วัน เวลา นัดสอบชดเชย"])},
  "1529": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สถานที่นัดสอบชดเชย"])},
  "1530": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รายการนักศึกษาที่พิจารณาแล้ว"])},
  "1531": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กรุณาแนบไฟล์ .pdf, .jpeg, .png เท่านั้น "])},
  "1532": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผู้เรียนที่รอการจัดกลุ่ม"])},
  "1533": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["สูงสุด ", _interpolate(_named("Numbermaximum")), " คน"])},
  "1534": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["จำนวนกลุ่มเรียน"])},
  "1535": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วิชาโท, วิชาเอก, หลักสูตร, ชั้นปี, สำนักวิชา, กลุ่มสักนักวิชา (ค่าเริ่มต้น)"])},
  "1536": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["หมายเหตุ: ผู้เรียนที่ไม่มีคะแนนหรือผู้เรียนที่ไม่พบข้อมูลระบบจะกำหนดคะแนนเป็น 0.00"])},
  "1537": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ดาวน์โหลดเทมเพลตคะแนนสอบ"])},
  "1538": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผลการอัปโหลดไฟล์คะแนนสอบ"])},
  "1539": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แก้ไขไฟล์คะแนนสอบ"])},
  "1540": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["จำนวนผู้เรียนทั้งหมด"])},
  "1541": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["จำนวนผู้เรียนที่มีคะแนนสอบ"])},
  "1542": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["จำนวนผู้เรียนที่ไม่มีคะแนนสอบ"])},
  "1543": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["จำนวนผู้เรียนที่รูปแบบคะแนนสอบไม่ถูกต้อง"])},
  "1544": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["จำนวนผู้เรียนที่ไม่มีในไฟล์คะแนนสอบ"])},
  "1545": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คะแนนสอบ"])},
  "1546": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การยกเลิกส่งผลให้ข้อมูลสูญหาย คุณยืนยันที่จะยกเลิก ใช่หรือไม่"])},
  "1547": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ประเภทห้องสอบ"])},
  "1548": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["อุปกรณ์ที่ใช้ในการสอบ"])},
  "1549": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สอบด้วยกระดาษ/ ดินสอ/ ปากกา"])},
  "1550": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สอบด้วยคอมพิวเตอร์"])},
  "1551": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สอบด้วยเเท็บเล็ต"])},
  "1552": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สอบด้วยไอแพด"])},
  "1553": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สอบด้วยโทรศัพท์มือถือ"])},
  "1554": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รูปแบบการสอบ"])},
  "1555": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สอบเขียน"])},
  "1556": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สอบฟัง"])},
  "1557": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สอบดูสไลด์"])},
  "1558": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สอบพูด"])},
  "1559": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การจัดการหลักสูตร"])},
  "1560": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ค้นหาหลักสูตร"])},
  "1561": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สร้างหลักสูตร"])},
  "1562": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เลือกระดับปริญญา"])},
  "1563": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ข้อมูลพื้นฐาน"])},
  "1564": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เพิ่มชื่อหลักสูตร"])},
  "1565": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เพิ่มชื่อปริญญา"])},
  "1566": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชื่อปริญญาและสาขาวิชา"])},
  "1567": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชื่อเต็มปริญญาและสาขาวิชา (ไทย)"])},
  "1568": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชื่อเต็มปริญญาและสาขาวิชา (อังกฤษ)"])},
  "1569": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชื่อย่อปริญญาและสาขาวิชา (ไทย)"])},
  "1570": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชื่อย่อปริญญาและสาขาวิชา (อังกฤษ)"])},
  "1571": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เพิ่มชื่อปริญญาและสาขาวิชา"])},
  "1572": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชื่อสาขา"])},
  "1573": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชื่อสาขา (ไทย)"])},
  "1574": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชื่อสาขา (อังกฤษ)"])},
  "1575": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชื่อเต็มปริญญา"])},
  "1576": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชื่อเต็มปริญญา (ไทย)"])},
  "1577": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชื่อเต็มปริญญา (อังกฤษ)"])},
  "1578": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชื่อย่อปริญญา"])},
  "1579": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชื่อย่อปริญญา (ไทย)"])},
  "1580": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชื่อย่อปริญญา (อังกฤษ)"])},
  "1581": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชื่อหลักสูตรนี้ มีอยู่ในระบบแล้ว ไม่สามารถบันทึกได้ คุณต้องการแก้ไข ใช่หรือไม่"])},
  "1582": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชื่อปริญญานี้ มีอยู่ในระบบแล้ว ไม่สามารถบันทึกได้ คุณต้องการแก้ไข ใช่หรือไม่"])},
  "1583": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ภาษาจีน"])},
  "1584": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชื่อหลักสูตร (ไทย)"])},
  "1585": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชื่อหลักสูตร (อังกฤษ)"])},
  "1586": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไฟล์คะแนนสอบ"])},
  "1587": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ภาษาไทย"])},
  "1588": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ภาษาอังกฤษ"])},
  "1589": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผู้เรียนที่จัดกลุ่มเรียนแล้ว"])},
  "1590": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คืนค่าการจัดกลุ่มเรียน"])},
  "1591": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กำหนดผู้เรียนที่ต้องการย้ายออก"])},
  "1592": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["จำนวนผู้เรียนที่ระบุมากกว่าที่กำหนด"])},
  "1593": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รอกำหนดผู้เรียน"])},
  "1594": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["ลบ", _interpolate(_named("studygroup")), "สำเร็จ"])},
  "1595": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กลุ่มเรียนที่ต้องการย้ายเข้า"])},
  "1596": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่ว่าง"])},
  "1597": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["บันทึกผลการพิจารณา \\n(ผู้ประสานงานรายวิชา หรือ ผู้สอน)"])},
  "1598": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผู้ประสานงานรายวิชา หรือ ผู้สอน\\nบันทึกผลการพิจารณา"])},
  "1599": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["นักศึกษา \\nยื่นคำร้อง"])},
  "1600": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("Totalcourse")), " วิชา"])},
  "1601": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สอบชดเชย"])},
  "1602": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การเปลี่ยนหน้า ทำให้ข้อมูลในฟอร์มสูญหาย \\nคุณยืนยันที่จะเปลี่ยนหน้า ใช่หรือไม่"])},
  "1603": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["การประมวลผลอาจใช้เวลาประมาณ ", _interpolate(_named("Start")), "-", _interpolate(_named("End")), " นาที\\n\nกรุณาอย่าออกจากหน้านี้ขณะที่ระบบกำลังประมวลผล"])},
  "1604": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["ชั้นปี ", _interpolate(_named("year"))])},
  "1605": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["ชั้นปี ", _interpolate(_named("yearFirst")), " และ ชั้นปี ", _interpolate(_named("yearSecon"))])},
  "1606": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รายละเอียดกลุ่ม"])},
  "1607": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เวลาสอบชดเชย"])},
  "1608": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["เอกสารแนบ ", _interpolate(_named("Number"))])},
  "1609": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กำหนดผู้เรียนรายบุคคล"])},
  "1610": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ข้อมูลผู้เรียน"])},
  "1611": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กำหนดแล้ว"])},
  "1612": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["กลุ่มที่ ", _interpolate(_named("Number"))])},
  "1613": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["จำนวนผู้เรียนที่ต้องการย้าย มากกว่าจำนวนที่นั่งเรียนในกลุ่มนี้ คุณยืนยันที่จะย้ายและขยายกลุ่มเรียนนี้ใช่หรือไม่"])},
  "1614": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การคืนค่าการจัดกลุ่มเรียนทำให้ข้อมูลสูญหาย คุณยืนยันที่จะยกเลิกใช่หรือไม่"])},
  "1615": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การลบกลุ่มเรียนทำให้ข้อมูลสูญหาย คุณยืนยันที่จะลบ ใช่หรือไม่"])},
  "1616": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["ย้าย", _interpolate(_named("msg")), "สำเร็จ"])},
  "1617": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กรุณากำหนดข้อมูลผู้เรียนที่ต้องการย้ายออก"])},
  "1618": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["หลักสูตรนี้ได้ถูกลบไปแล้ว ไม่สามารถลบได้"])},
  "1619": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["หลักสูตรนี้ได้ถูกลบไปแล้ว ไม่สามารถแก้ไขได้"])},
  "1620": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["หลักสูตรนี้มีสถานะเปิดใช้งาน ไม่สามารถลบได้"])},
  "1621": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["หลักสูตรนี้มีสถานะเปิดใช้งาน ไม่สามารถแก้ไขได้"])},
  "1622": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การลบหลักสูตรทำให้ข้อมูลหลักสูตรสูญหาย คุณยืนยันที่จะลบ ใช่หรือไม่"])},
  "1623": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่มีข้อมูลหลักสูตร จึงไม่สามารถใช้งานตัวกรองได้"])},
  "1624": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["จํานวนหน่วยกิตที่เรียนตลอดหลักสูตร ", _interpolate(_named("Number")), " หน่วยกิต"])},
  "1625": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เปิดใช้งานชั่วคราว"])},
  "1626": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เปิดใช้งาน"])},
  "1627": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ระงับการใช้งานชั่วคราว"])},
  "1628": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ปิดใช้งาน"])},
  "1629": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ระหว่างดำเนินการ"])},
  "1630": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รายละเอียดหลักสูตร"])},
  "1631": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การปรับปรุงหลักสูตร"])},
  "1632": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กำหนดรหัสรายวิชา"])},
  "1633": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กำหนดโครงสร้างหลักสูตร"])},
  "1634": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["โครงสร้างจบ"])},
  "1635": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กำหนดแผนการศึกษา"])},
  "1636": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผู้ใช้งานปฏิทินการศึกษา"])},
  "1637": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["นักศึกษารหัส ", _interpolate(_named("ID"))])},
  "1638": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คุณต้องการเปลี่ยนระบบการศึกษาของหลักสูตรนี้ ใช่หรือไม่"])},
  "1639": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กรอกพิน (PIN) เพื่อยืนยันสิทธิ์"])},
  "1640": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กำหนดชั่วโมงสอนและห้องเรียน"])},
  "1641": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["จำนวนสัปดาห์การสอน"])},
  "1642": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["จำนวนชั่วโมงการสอน"])},
  "1643": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ห้องเรียนบรรยาย"])},
  "1644": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ห้องปฏิบัติการทางคอมพิวเตอร์"])},
  "1645": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ห้องปฏิบัติการศูนย์เครื่องมือวิทย์ฯ"])},
  "1646": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ออนไลน์"])},
  "1647": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["จัดหาห้องเรียนด้วยตนเอง"])},
  "1648": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รายละเอียดการสอน"])},
  "1649": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รอกำหนดชั่วโมงสอน"])},
  "1650": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กำหนดชั่วโมงสอนแล้ว"])},
  "1651": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชั่วโมง/สัปดาห์"])},
  "1652": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สัปดาห์/ภาคการศึกษา"])},
  "1653": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รายวิชาในหมวดวิชาเฉพาะ"])},
  "1654": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วิทยานิพนธ์"])},
  "1655": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รายวิชาสหกิจศึกษา"])},
  "1656": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การเปลี่ยนประเภทรายวิชา ทำให้จำนวนหน่วยกิตเปลี่ยนแปลง คุณยืนยันที่จะเปลี่ยน ใช่หรือไม่"])},
  "1657": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กำหนดประเภทการประเมินการจัดการเรียนการสอน"])},
  "1658": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ประเภทการประเมินการจัดการเรียนการสอน"])},
  "1659": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รายวิชาบรรยายและปฏิบัติการ"])},
  "1660": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รายวิชาบรรยาย"])},
  "1661": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รายวิชาปฏิบัติการ"])},
  "1662": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รายวิชาปฏิบัติการในแหล่งฝึกหรือสถานประกอบการ"])},
  "1663": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รายวิชาพลศึกษาและวิทยาศาสตร์การกีฬาในรายวิชาบรรยายและปฏิบัติการ"])},
  "1664": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รายวิชากีฬาในรายวิชาปฏิบัติการ"])},
  "1665": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ปฏิทินการศึกษา"])},
  "1666": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชื่อปฏิทินการศึกษา"])},
  "1667": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ระบบการศึกษา"])},
  "1668": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วันเริ่มต้นแสดงปฏิทิน"])},
  "1669": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["จำนวนกิจกรรม"])},
  "1670": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สร้างปฏิทินการศึกษา"])},
  "1671": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กรุณาเลือกเทมเพลตปฏิทินการศึกษา"])},
  "1672": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ล่าสุด"])},
  "1673": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เทมเพลตทั้งหมด"])},
  "1674": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ภาคฤดูร้อน"])},
  "1675": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รายละเอียดปฏิทินการศึกษา"])},
  "1676": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วันเริ่มต้นปฏิทินการศึกษา"])},
  "1677": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กำหนดผู้ใช้"])},
  "1678": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รายละเอียดกิจกรรม"])},
  "1679": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กำหนดรายละเอียดกิจกรรม"])},
  "1680": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชื่อกิจกรรม (ไทย)"])},
  "1681": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชื่อกิจกรรม (อังกฤษ)"])},
  "1682": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คำอธิบายกิจกรรม (ไทย)"])},
  "1683": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คำอธิบายกิจกรรม (อังกฤษ)"])},
  "1684": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สัปดาห์เริ่มต้น"])},
  "1685": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สัปดาห์สิ้นสุด"])},
  "1686": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วันเริ่มต้นกิจกรรม"])},
  "1687": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วันสิ้นสุดกิจกรรม"])},
  "1688": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วันเวลาเริ่มต้น"])},
  "1689": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วันเวลาสิ้นสุด"])},
  "1690": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กำหนดการแจ้งเตือน"])},
  "1691": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["ข้อความ ", _interpolate(_named("number"))])},
  "1692": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กำหนดผู้รับ"])},
  "1693": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ช่วงวันประกาศ"])},
  "1694": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ก่อนวันเริ่มต้นกิจกรรม"])},
  "1695": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["จำนวนวัน"])},
  "1696": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วันแรกของกิจกรรม"])},
  "1697": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 วันก่อนวันสุดท้ายของกิจกรรม"])},
  "1698": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วันสุดท้ายของกิจกรรม"])},
  "1699": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รูปแบบการประกาศ"])},
  "1700": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ข่าวประกาศ"])},
  "1701": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ลบ"])},
  "1702": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ทำสำเนา"])},
  "1703": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["หน้าหลัก"])},
  "1704": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["อาจารย์/บุคลากร"])},
  "1705": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ค้นหารายวิชา"])},
  "1706": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["จองห้องบรรยาย"])},
  "1707": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["นักศึกษา ป.ตรี"])},
  "1708": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แบบฟอร์ม/คำร้อง"])},
  "1709": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คู่มือนักศึกษา"])},
  "1710": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["บัณฑิตศึกษา"])},
  "1711": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ระเบียบ/ข้อบังคับฯ"])},
  "1712": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["หลักสูตรที่เปิดสอน"])},
  "1713": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["จำนวนนักศึกษา"])},
  "1714": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เว็บไซต์ที่เกี่ยวข้อง"])},
  "1715": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["อีเมลมหาวิทยาลัย"])},
  "1716": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["บริการ"])},
  "1717": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ตรวจสอบคุณวุฒิ"])},
  "1718": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ค้นหาข่าว"])},
  "1719": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ข่าวการชำระเงิน"])},
  "1720": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ดูทั้งหมด"])},
  "1721": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ข่าวทุนการศึกษา"])},
  "1722": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ข่าวหอพัก"])},
  "1723": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เวลาทำการ"])},
  "1724": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ส่งอีเมล"])},
  "1725": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แอปพลิเคชัน"])},
  "1726": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["อีเมล"])},
  "1727": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ส่งสำเร็จ"])},
  "1728": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ส่งไม่สำเร็จ"])},
  "1729": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผู้สอนจากภายในมหาวิทยาลัย"])},
  "1730": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผู้สอนจากภายนอกมหาวิทยาลัย"])},
  "1731": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["จำนวนผู้สอน"])},
  "1732": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผู้ช่วยสอน"])},
  "1733": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กำหนดการสอน"])},
  "1734": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ทุกครั้งทุกสัปดาห์"])},
  "1735": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["บางครั้งบางสัปดาห์"])},
  "1736": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รายการผู้สอน"])},
  "1737": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กำหนดกลุ่มเรียนให้ผู้สอน"])},
  "1738": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สถานะการกำหนดผู้สอน"])},
  "1739": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่มีการกำหนดผู้สอน"])},
  "1740": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กำหนดผู้สอนแล้วบางสัปดาห์"])},
  "1741": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กำหนดผู้สอนแล้วทุกสัปดาห์"])},
  "1742": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["จำนวนกลุ่มเรียนทั้งหมด"])},
  "1743": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เลือกกลุ่มที่"])},
  "1744": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["จำนวนผู้สอนทั้งหมด"])},
  "1745": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กลุ่มเรียนที่กำหนดแล้ว"])},
  "1746": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เพิ่มกลุ่ม"])},
  "1747": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ยกเลิกกลุ่ม"])},
  "1748": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่พบกลุ่มที่ท่านระบุ กรุณาแก้ไขกลุ่ม"])},
  "1749": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["หมวดวิชา"])},
  "1750": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["หน่วยกิตทั้งหมด"])},
  "1751": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["หมวดวิชาเลือกเสรี"])},
  "1752": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["หมวดวิชาเฉพาะ"])},
  "1753": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["หมวดศึกษาทั่วไป"])},
  "1754": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สร้างกลุ่มวิชา"])},
  "1755": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชื่อกลุ่มวิชา (ไทย)"])},
  "1756": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชื่อกลุ่มวิชา (อังกฤษ)"])},
  "1757": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["มีกลุ่มวิชาย่อยภายใต้กลุ่มวิชานี้"])},
  "1758": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เรียนทุกกลุ่มวิชาที่อยู่ภายใต้กลุ่มวิชานี้"])},
  "1759": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เรียนกลุ่มวิชาใดกลุ่มหนึ่งภายใต้กลุ่มวิชานี้"])},
  "1760": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่มีกลุ่มวิชาย่อยภายใต้กลุ่มวิชานี้"])},
  "1761": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เรียนทุกรายวิชาที่อยู่ภายใต้กลุ่มวิชานี้"])},
  "1762": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เรียนวิชาใดก็ได้ภายใต้กลุ่มวิชานี้"])},
  "1763": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["หน่วยกิตตลอดหลักสูตรต้องไม่น้อยกว่า ", _interpolate(_named("minimum")), " แต่ไม่เกิน ", _interpolate(_named("maximum"))])},
  "1764": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คลิกเพื่อดูรายวิชาภายในกลุ่ม"])},
  "1765": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คลิกเพื่อกำหนดรายวิชาภายในกลุ่ม"])},
  "1766": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การลบทำให้ข้อมูลในกลุ่มวิชานี้สูญหาย คุณยืนยันที่จะลบ ใช่หรือไม่"])},
  "1767": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["กรุณากรอกจำนวนหน่วยกิตตั้งแต่ ", _interpolate(_named("numberCredit")), " ขึ้นไป"])},
  "1768": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คน"])},
  "1769": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["ภาคการศึกษาที่ ", _interpolate(_named("Number"))])},
  "1770": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ภาคการศึกษา"])},
  "1771": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เทมเพลตปฏิทินการศึกษา"])},
  "1772": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วันเริ่มต้น"])},
  "1773": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชื่อกิจกรรม"])},
  "1774": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คำอธิบายกิจกรรม"])},
  "1775": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เลือกกลุ่ม"])},
  "1776": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เลือกผู้ประสานงานรายวิชา"])},
  "1777": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ข้อความแจ้งเตือน (ไทย)"])},
  "1778": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ข้อความแจ้งเตือน (อังกฤษ)"])},
  "1779": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["กรุณากรอกหมายเลขกลุ่มเรียนไม่เกิน ", _interpolate(_named("maxNo"))])},
  "1780": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ปฏิทินได้สิ้นสุดการใช้งานแล้ว ไม่สามารถลบได้"])},
  "1781": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่กำหนดสัปดาห์สิ้นสุด"])},
  "1782": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กำหนดอาจารย์ที่ปรึกษา"])},
  "1783": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["จำนวนนักศึกษาทั้งหมด"])},
  "1784": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รอกำหนดอาจารย์ที่ปรึกษา"])},
  "1785": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กำหนดอาจารย์ที่ปรึกษาแล้ว"])},
  "1786": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เปลี่ยนอาจารย์ที่ปรึกษา"])},
  "1787": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รายการนักศึกษา"])},
  "1788": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คะแนนความดี"])},
  "1789": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["จำนวนทั้งหมด"])},
  "1790": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รอยืนยัน"])},
  "1791": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เลือกจากลำดับ"])},
  "1792": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["อาจารย์ภายในสาขา"])},
  "1793": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["อาจารย์ภายนอกสาขา"])},
  "1794": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ยืนยันนักศึกษาในที่ปรึกษาสำเร็จ"])},
  "1795": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["ลำดับเริ่มต้นตั้งแต่ ", _interpolate(_named("Number")), " ขึ้นไป"])},
  "1796": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กรุณากรอกลำดับเริ่มต้นน้อยกว่าลำดับสิ้นสุด"])},
  "1797": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["ลำดับสิ้นสุดไม่เกิน ", _interpolate(_named("Number"))])},
  "1798": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กรุณาเลือกลำดับสิ้นสุดมากกว่าลำดับเริ่มต้น"])},
  "1799": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เปลี่ยนอาจารย์ที่ปรึกษา"])},
  "1800": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["อาจารย์ที่ปรึกษาปัจจุบัน"])},
  "1801": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["อาจารย์ที่ปรึกษาใหม่"])},
  "1802": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เลือกอาจารย์ที่ปรึกษา"])},
  "1803": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ยืนยันแล้ว"])},
  "1804": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชื่อรายวิชา"])},
  "1805": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รายวิชาที่หลักสูตรเปิดสอน"])},
  "1806": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รายวิชาที่เปิดสอนโดยหลักสูตรอื่น"])},
  "1807": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ตามแผนการศึกษา"])},
  "1808": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["เปิดล่าสุดเมื่อ ", _interpolate(_named("Year"))])},
  "1809": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เปิดให้หลักสูตรอื่น"])},
  "1810": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["มีนักศึกษาที่ตกค้าง"])},
  "1811": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["มีนักศึกษาที่ยื่นคำร้อง"])},
  "1812": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ติดต่อหัวหน้าสาขา"])},
  "1813": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กำหนดข้อมูลที่ต้องการนำเข้า"])},
  "1814": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["ภาคการศึกษา ", _interpolate(_named("Year"))])},
  "1815": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผลการนำเข้าข้อมูล"])},
  "1816": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กำลังนำเข้าข้อมูลการสอบ"])},
  "1817": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่สามารถเพิ่มกลุ่มสอนได้ เนื่องจากไม่พบหมายเลขกลุ่มเรียน กรุณาแก้ไขหมายเลขกลุ่มเรียน"])},
  "1818": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กำลังนำเข้าข้อมูลกลุ่มเรียนและวิธีการจัดกลุ่มเรียน"])},
  "1819": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ระบบกำลังสร้างกลุ่มเรียน"])},
  "1820": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กำลังนำเข้าข้อมูลรายการสอน"])},
  "1821": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กำลังนำเข้ากลุ่มเรียนให้ผู้สอน"])},
  "1822": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กำลังนำเข้าผู้ประสานงานรายวิชา"])},
  "1823": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["นำเข้าข้อมูลสำเร็จ"])},
  "1824": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ดำเนินการไม่สมบูรณ์"])},
  "1825": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กำหนดผู้สอนแล้ว"])},
  "1826": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รอกำหนดกลุ่ม"])},
  "1827": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["นำเข้าข้อมูล"])},
  "1828": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กำลังนำเข้าข้อมูล"])},
  "1829": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คำร้องขอย้ายหลักสูตร(ภายในมหาวิทยาลัย)"])},
  "1830": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รายละเอียด คำร้องขอย้ายหลักสูตร(ภายในมหาวิทยาลัย)"])},
  "1831": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สำนักวิชา และ หลักสูตรที่ขอย้ายเข้า"])},
  "1832": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คุณสมบัติการย้ายเข้าหลักสูตร"])},
  "1833": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เหตุผลที่ขอย้ายเข้า"])},
  "1834": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ค่าธรรมเนียมคำร้องขอย้ายหลักสูตร(ภายในมหาวิทยาลัย)"])},
  "1835": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*หลังจากชำระเงิน ไม่สามารถเรียกคืนค่าธรรมเนียมได้"])},
  "1836": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*สามารถตรวจสอบการชำระเงินของคุณ ได้ในวันทำการถัดไป"])},
  "1837": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สามารถตรวจสอบการชำระเงินของคุณ ได้ในวันทำการถัดไป"])},
  "1838": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["จำนวนเงิน"])},
  "1839": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชำระภายในวัน"])},
  "1840": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["บันทึกลงเครื่อง"])},
  "1841": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["นักศึกษาไม่สามารถส่งคำร้องได้ เนื่องจากมีคุณสมบัติไม่ตรงตามเงื่อนไข ดังนี้"])},
  "1842": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*หากนักศึกษาต้องการสอบถามข้อมูลเพิ่มเติม กรุณาติดต่อหัวหน้าสาขาของหลักสูตรที่ต้องการย้ายเข้า"])},
  "1843": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ตั้งแต่ภาคการศึกษา"])},
  "1844": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สำนักวิชาที่ขอย้ายเข้า"])},
  "1845": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["หลักสูตรที่ขอย้ายเข้า"])},
  "1846": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ความเห็นของหัวหน้าสาขา"])},
  "1847": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผลการพิจารณาจากคณบดี/คณะกรรมการประจำสำนักวิชา"])},
  "1848": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ย้ายเข้า"])},
  "1849": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ส่วนการเงินและบัญชี"])},
  "1850": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รอชำระค่าบริการ"])},
  "1851": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชำระค่าบริการแล้ว"])},
  "1852": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผลการตรวจสอบ"])},
  "1853": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผ่านคุณสมบัติเบื้องต้น"])},
  "1854": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่ผ่านคุณสมบัติเบื้องต้น"])},
  "1855": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เห็นชอบให้ย้าย"])},
  "1856": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่เห็นชอบให้ย้าย"])},
  "1857": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เริ่มต้น"])},
  "1858": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สิ้นสุด"])},
  "1859": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การเลือกสาขาของนักศึกษา"])},
  "1860": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ภายในสาขา"])},
  "1861": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ภายนอกสาขา"])},
  "1862": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รายการคะแนนความดี"])},
  "1863": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผ่าน"])},
  "1864": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่ผ่าน"])},
  "1865": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ความกตัญญู"])},
  "1866": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การรู้วินัย"])},
  "1867": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การมีใจอาสา"])},
  "1868": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การพัฒนาภาวะผู้นำ"])},
  "1869": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ความรักชาติ"])},
  "1870": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คีย์"])}
}