const Validation = {
  email: function (email: string) {
    if (email != "") {
      if (/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email)) {
        return true;
      } else if (/^\w+@wu.ac.th$/.test(email)) {
        return true;
      } else {
        return false;
      }
    }
  },
  webUrl: function (url: string) {
    const pattern = new RegExp(
      "^(https?:\\/\\/)?" +
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" +
      "((\\d{1,3}\\.){3}\\d{1,3}))" +
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" +
      "(\\?[;&a-z\\d%_.~+=-]*)?" +
      "(\\#[-a-z\\d_]*)?$",
      "i"
    );
    if (url != null) {
      // console.log(pattern.test(url));
      if (pattern.test(url)) {
        return true;
      } else {
        return false;
      }
    }
  },
  facebookpage: function (facebookpage: string | null) {
    if (facebookpage !== null && facebookpage !== " ") {
      // console.log(
      //   /^(?:https?:\/\/)?(?:www\.)?(mbasic\.facebook|m\.facebook|facebook|fb)\.(com|me)\/(?:[\w-]+\/)*([\w.-]+)(\?(?:.*))?$/.test(
      //     facebookpage
      //   )
      // );
      if (
        /^(?:https?:\/\/)?(?:www\.)?(mbasic\.facebook|m\.facebook|facebook|fb)\.(com|me)\/(?:[\w-]+\/)*([\w.-]+)(\?(?:.*))?$/.test(
          facebookpage
        )
      ) {
        return true;
      } else {
        return false;
      }

    } else {
      return false;
    }
  },

  letterTH: function (letter: string) {
    // console.log(letter);
    // console.log(!/^[฿]+$/.test(letter));
    if (/^[ก-๏0-9- .]+$/.test(letter) && /^[฿]+$/.test(letter) == false) {
      return true;
    } else {
      return false;
    }
  },

  letterEN: function (letter: string) {
    if (/^[a-zA-Z0-9- .]+$/.test(letter) && /^[ก-๏]+$/.test(letter) == false) {
      return true;
    } else {
      return false;
    }
  },
  letterENUnderScore: function (letter: string) {
    if (/^[a-zA-Z0-9-_ .]+$/.test(letter) && /^[ก-๏]+$/.test(letter) == false) {
      return true;
    } else {
      return false;
    }
  },

  letterTHSymbol: function (letter: string) {
    // console.log(letter);
    // console.log(!/^[฿]+$/.test(letter));
    if (/^[ก-๏0-9- ()]+$/.test(letter) && /^[฿]+$/.test(letter) == false) {
      return true;
    } else {
      return false;
    }
  },

  letterENSymbol: function (letter: string) {
    if (/^[a-zA-Z0-9-()/ ]+$/.test(letter) && /^[ก-๏.]+$/.test(letter) == false) {
      return true;
    } else {
      return false;
    }
  },
  addressTH: function (letter: string) {
    // console.log(letter);
    // console.log(!/^[฿]+$/.test(letter));
    if (/^[ก-๏0-9,-/ .-–]+$/.test(letter) && /^[฿]+$/.test(letter) == false) {
      return true;
    } else {
      return false;
    }
  },

  addressEN: function (letter: string) {
    // console.log()
    if (/^[a-zA-Z0-9,-/ .-–]+$/.test(letter)) {
      return true;
    } else {
      return false;
    }
  },

  PhoneNumberTH: function (letter: string) {
    // console.log(
    //   "PhoneNumberTH" +
    //   /^[~/\]/`/!@#$%^&*()_+={}/[|;:",.<>?ก-๏0-9- ]+$/.test(letter)
    // );
    if (/^[~/\]/`/!@#$%^&*()_+={}/[|;:",.<>?ก-๏0-9- ]+$/.test(letter)) {
      return true;
    } else {
      return false;
    }
  },

  PhoneNumberEN: function (letter: string) {
    // console.log(/^[ก-๏.]+$/.test(letter));
    // console.log(/[a-zA-Z0-9- ]+$/.test(letter));
    if (
      /^[~/\]/`/!@#$%^&*()_+={}/[|;:",.<>?a-zA-Z0-9- ]+$/.test(letter) &&
      /^[ก-๏.]+$/.test(letter) == false
    ) {
      return true;
    } else {
      return false;
    }
  },
  InitialTH: function (letter: string) {
    if (/^[ก-๏-.()_/ ]+$/.test(letter)) {
      return true;
    } else {
      return false;
    }
  },
  InitialEN: function (letter: string) {
    if (/^[A-Za-z-\s\d.()_/]+$/.test(letter)) {
      return true;
    } else {
      return false;
    }
  },
  TextDisplayTH: function (letter: string) {
    if (/^[0-9ก-๏-.()_/ ]+$/.test(letter)) {
      return true;
    } else {
      return false;
    }
  },
  TextDisplayEN: function (letter: string) {
    if (/^[0-9A-Za-z-\s\d.()_/]+$/.test(letter)) {
      return true;
    } else {
      return false;
    }
  },
  number: function (number: string) {
    if (number != null) {
      if (/^[0-9]+$/.test(number)) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  },
  numeric: function (numeric: string) {
    if (numeric != null) {
      if (/^[-0-9.]+$/.test(numeric)) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  },
  grade: function (grade:string) {
    if (grade != null) {
      if (/^[0-9.][0-9.]*$/.test(grade)) {
        // Parse numeric and max as floating-point numbers for comparison
        const numericValue = parseFloat(grade);
        const maxValue = parseFloat('4.00');
  
        return numericValue <= maxValue;
      } else {
        return false;
      }
    } else {
      return false;
    }
  },
 
  currency: function (number: string) {
    if (number != null) {
      if (/^[0-9]{1,3}(,[0-9]{3})*(\.[0-9]{1,2})?$/.test(number)) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  },
  phonenumber: function (phonenumber: string) {
    if (phonenumber != null) {
      if (
        (phonenumber.length >= 8 && /^0[1-9]{1}[0-9]{8}$/.test(phonenumber)) ||
        /^0[1-9]{1}[0-9]{6}$/.test(phonenumber) ||
        /^0[1-9]{1}[0-9]{7}$/.test(phonenumber)
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  },
  countrycode: function (countrycode: string) {
    // console.log(/^[0-9-]+$/.test(countrycode));
    if (countrycode != null) {
      if (/^[0-9-]+$/.test(countrycode)) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  },
  personalID: function (cardID: string) {
    if (cardID.length != 13) {
      return false;
    }
    let sum = 0;
    // STEP 1 - get only first 12 digits
    for (let i = 0; i < 12; i++) {
      // STEP 2 - multiply each digit with each index (reverse)
      // STEP 3 - sum multiply value together
      sum += parseInt(cardID.charAt(i)) * (13 - i);
    }
    // STEP 4 - mod sum with 11
    const mod = sum % 11;
    // STEP 5 - subtract 11 with mod, then mod 10 to get unit
    const check = (11 - mod) % 10;

    // STEP 6 - if check is match the digit 13th is correct
    if (check == parseInt(cardID.charAt(12))) {
      return true;
    }
    return false;
  },
  EducationTH: function (letter: string) {
    if (/^[ก-๏() -]+$/.test(letter)) {
      return true;
    } else {
      return false;
    }
  },
  EducationEN: function (letter: string) {
    if (/^[A-Za-z() -]+$/.test(letter)) {
      return true;
    } else {
      return false;
    }
  },
};

export default Validation;
