import { h, ComponentOptions } from 'vue';

const attrs = {
    viewBox: '0 0 24 25',
    fill: 'currentColor',
    role: 'img',
    focusable: 'false',
    xmlns:"http://www.w3.org/2000/svg"
};

export function genIcon(name: string, svgContent: string): ComponentOptions {
    return {
        name,
        render() {
            return h(
                'svg',
                {
                    ...attrs,
                    innerHTML: svgContent,
                },
            );
        },
    };
}