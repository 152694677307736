import { createRouter, createWebHistory } from "vue-router";
import NotFound from "../views/404.vue";
import VueCookies from "vue-cookies";

const routes = [
  { path: "/:catchAll(.*)", component: NotFound },
  // {
  //   path: "/login",
  //   name: "login",
  //   component: () =>
  //     import("../views/Home.vue"),

  // },
  // {
  //   path: "/",
  //   name: "About",
  //   component: () => import("../views/About.vue"),
  // },

  {
    path: "/UserRoleGroup",
    name: "UserRoleGroup",

    component: () => import("../views/systemadmin/UserroleGroup.vue"),
  },

  {
    path: "/Usergroup",
    name: "Usergroup",

    component: () => import("../views/systemadmin/Usergroup.vue"),
  },

  // {
  //   path: "/",
  //   name: "Campus ",
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import("@/views/systemadmin/Home.vue"),
  // },
  {
    path: "/campus",
    name: "Campus",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("@/views/systemadmin/Home.vue"),
  },
  {
    path: "/agency",
    name: "Agency",
    component: () => import("@/views/systemadmin/Agency.vue"),
  },
  {
    path: "/userField",
    name: "Userfield",
    component: () => import("@/views/systemadmin/PatronField.vue"),
  },
  {
    path: "/template/PreviewTemplate/:TypeName",
    name: "PreviewTemplate",
    props: true,
    component: () => import("@/views/systemadmin/template/previewTemplate.vue"),
  },
  {
    path: "/template",
    name: "Template",
    component: () => import("@/views/systemadmin/Template.vue"),
  },
  // {
  //   path: "/systemadmin/patron",
  //   name: "Patron",
  //   component: () =>
  //     import("@/views/systemadmin/Patron.vue"),
  // },

  {
    path: "/position",
    name: "Position",
    component: () => import("@/views/systemadmin/Position.vue"),
  },
  {
    path: "/education",
    name: "Education",
    component: () => import("@/views/systemadmin/Education.vue"),
  },
  {
    path: "/usertype",
    name: "Usertype",
    component: () => import("@/views/systemadmin/UserType.vue"),
  },
  {
    path: "/prefix",
    name: "Prefix",
    component: () => import("@/views/systemadmin/Prefix.vue"),
  },
  {
    path: "/parameter",
    name: "Parameter",
    component: () => import("@/views/systemadmin/Parameter.vue"),
  },
  {
    path: "/academic",
    name: "Academic Organization",
    component: () => import("@/views/systemadmin/AcademicOrganization.vue"),
  },
  {
    path: "/userinfo",
    name: "User",
    component: () => import("@/views/systemadmin/userinfo/userinfo.vue"),
  },
  {
    path: "/userrolegroup",
    name: "UserRoleGroup",
    component: () => import("@/views/systemadmin/UserroleGroup.vue"),
  },
  {
    path: "/userrolemanager",
    name: "UserRoleManage",
    component: () => import("@/views/systemadmin/UserRoleManage.vue"),
  },
  {
    path: "/userinfo/viewuserinfo/:memberID",
    name: "ViewUser",
    props: true,
    component: () => import("@/views/systemadmin/userinfo/viewuserinfo.vue"),
  },
  {
    path: "/userinfo/Previewuserinfo/:memberID",
    name: "PreviewUser",
    props: true,
    component: () => import("@/views/systemadmin/userinfo/PreviewUser.vue"),
  },

  {
    path: "/test/about",
    name: "About",
    component: () => import("@/views/About.vue"),
  },

  // {
  //   path: "/test/loading1/:id",

  //   name: "loading1",
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () =>
  //     import("@/views/systemadmin/Home.vue"),
  // },
  // {
  //   path: "/test/loading2/:id",
  //   name: "loading2",
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () =>
  //     import("@/views/systemadmin/Home.vue"),
  // },
  // {
  //   path: "/test/loading3/:id",
  //   name: "loading3",
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () =>
  //     import("@/views/systemadmin/Home.vue"),
  // },
  // {
  //   path: "/test/loading4/:id",
  //   name: "loading4",
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () =>
  //     import("@/views/systemadmin/Home.vue"),
  // },
  {
    path: "/systemword",
    name: "Systemword",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("@/views/systemword/SystemWord.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});
router.beforeEach((to: any, from: any, next: any) => {
  // console.log(to.fullPath);
  // console.log(VueCookies.get("moduleAccess"))
  let menuID: any;
  if (to.fullPath.split("/")[1] == "campus") menuID = "005001";
  else if (to.fullPath.split("/")[1] == "agency") menuID = "005002";
  else if (to.fullPath.split("/")[1] == "position") menuID = "005003";
  else if (to.fullPath.split("/")[1] == "academic") menuID = "005004";
  else if (to.fullPath.split("/")[1] == "education") menuID = "005005";
  else if (to.fullPath.split("/")[1] == "usertype") menuID = "005006";
  else if (to.fullPath.split("/")[1] == "prefix") menuID = "005007";
  else if (to.fullPath.split("/")[1] == "parameter") menuID = "005008";
  else if (to.fullPath.split("/")[1] == "userField") menuID = "005009";
  else if (to.fullPath.split("/")[1] == "template") menuID = "005010";
  else if (to.fullPath.split("/")[1] == "userinfo") menuID = "005011";

  const moduleAccess = VueCookies.get("moduleAccess")?.find((module: any) => {
    return module.intModuleID == "005";
  });
  const menuAccess = moduleAccess?.lstMenuAccess?.find((menu: any) => {
    return menu.intMenuID == menuID;
  });
  // console.log(menuID)
  // เขียนโค้ดตรวจสอบสิทธิ์ที่นี่
  if (menuAccess != null || moduleAccess != undefined) {
    if (to.fullPath == "/") {
      next("/campus");
    } else if ((menuAccess != null && menuAccess != undefined) || to.fullPath.split("/")[1] == "systemword"|| menuID==null) {
      next(); // อนุญาตให้เข้าถึงหน้า
    } else {
       //window.location.href = 'http://localhost:8080'
      window.location.href = "https://cessystem.wu.ac.th/landingdev";
      next('/'); // ถ้าไม่มีสิทธิ์
    }
  } else {
    window.location.href = "https://cessystem.wu.ac.th/landingdev";
       //window.location.href = 'http://localhost:8081/landingdev'

  }

});
export default router;