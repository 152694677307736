<template>
  <div class="hello">
    <StatusCode
      codeNumber="404"
      :detail="
        'The requested  ces.wu.ac.th' +
        $route.fullPath +
        ' was not found on this server.'
      "
      message="Page not found"
    />
  </div>
</template>

<script>
import StatusCode from "../components/statuscode/error.vue";
export default {
  components: {
    StatusCode,
  },
};
</script>