// const customfilter = {
//     filterLang: (items: any, locales: string) => {
//         let index = "";
//         if (items !== undefined) {
//             Object.keys(items).filter((key) => {
//                 //console.log(key.includes(locales == "en" ? "NameEN" : "NameTH"))
//                 if (key.toLowerCase().includes(locales == "en" || locales == "EN" ? "nameen" : "nameth")) {
//                     // console.log(key)
//                     index = key;

//                 }
//                 // else if (key.includes(locales == "en" || locales == "EN" ? "EN" : "TH")) {
//                 //     // console.log(key)
//                 //     index = key;
//                 // }
//             })
//         }
//         return index;
//     },
// }

// export default customfilter;
const customfilter = {
    filterLang: (items: any, locales: string) => {
        let index = "";
        if (items !== undefined) {
            index = Object.keys(items).filter(key=>{return key.includes(locales?.toUpperCase())})[0]
        }
        return index;
    },
}

export default customfilter;